<template>
  <svg t="1727401389476" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
       p-id="17901" :width="width" :height="height">
    <path
        d="M634.733714 915.529143a82.358857 82.358857 0 0 1-81.188571-68.534857l-37.376-214.747429a3.218286 3.218286 0 0 0-6.290286 0L472.502857 846.994286a82.432 82.432 0 0 1-81.261714 68.534857H153.380571a83.090286 83.090286 0 0 1-82.139428-92.233143l75.629714-641.755429a82.505143 82.505143 0 0 1 81.846857-73.142857h565.833143a82.358857 82.358857 0 0 1 81.846857 73.142857l75.629715 641.755429a82.432 82.432 0 0 1-20.406858 64.365714 81.481143 81.481143 0 0 1-61.44 27.867429z m-40.301714-296.886857l37.376 214.747428a3.145143 3.145143 0 0 0 2.925714 2.706286h235.52a3.145143 3.145143 0 0 0 2.340572-0.877714 2.925714 2.925714 0 0 0 0.658285-2.706286l-53.540571-456.118857c-59.977143 0-298.203429-0.585143-462.482286-0.585143H203.995429l-53.76 456.411429a4.900571 4.900571 0 0 0 0.877714 2.706285 3.291429 3.291429 0 0 0 2.048 0.877715H391.314286a2.925714 2.925714 0 0 0 2.998857-2.706286l37.668571-214.747429a82.505143 82.505143 0 0 1 162.596572 0z m216.795429-314.514286l-13.385143-113.590857a3.437714 3.437714 0 0 0-3.291429-2.706286H228.717714a2.852571 2.852571 0 0 0-2.925714 2.706286l-13.458286 113.590857z"
        p-id="17902" fill="#1296db"></path>
  </svg>
</template>

<script>
export default {
  name: "LowerGarmentIcon",
  props: {
    width: {
      type: Number,
      default: 24
    },
    height: {
      type: Number,
      default: 24
    },
  }
};
</script>

<style scoped>
</style>
