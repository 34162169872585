<template>
  <svg t="1727400143398" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
       p-id="7377" :width="width" :height="height">
    <path d="M652.9653157 396.3639344L594.18591214 511.3122397h177.30840761l110.56714319-223.34625387z" fill="#8A1D22"
          p-id="7378"></path>
    <path d="M594.30879921 511.33396559l163.98025118 346.5823084L876.19479289 738.01718083 714.29411704 388.72388832z"
          fill="#366BB3" p-id="7379"></path>
    <path d="M534.94687015 747.34913083l223.34218024 110.56714318-108.39455386-229.09954191-114.94762638-58.77940358z"
          fill="#1E4499" p-id="7380"></path>
    <path d="M188.56348944 734.33532089l119.8977353 117.90302676 349.29465038-161.8938865-122.61347194-119.9887125z"
          fill="#78BD48" p-id="7381"></path>
    <path d="M299.12995369 510.98635131L188.56348944 734.34278918l229.09954191-108.39794856 58.7719353-114.95848931z"
          fill="#006536" p-id="7382"></path>
    <path d="M312.33590199 164.38571167L194.42948056 284.28752058l161.90135479 349.29057676 119.98463888-122.60668258z"
          fill="#F08D29" p-id="7383"></path>
    <path d="M312.33590199 164.38571167l108.39387494 229.09954191 114.9483053 58.77940356V274.95217591z" fill="#E16A22"
          p-id="7384"></path>
    <path d="M412.86907725 331.96499281l122.61347196 119.98463888 346.57891373-163.97957224L762.1630487 170.06363802z"
          fill="#E32129" p-id="7385"></path>
  </svg>
</template>

<script>
export default {
  name: "FeatureIcon",
  props: {
    width: {
      type: Number,
      default: 48
    },
    height: {
      type: Number,
      default: 48
    },
  }
};
</script>

<style scoped>
</style>
