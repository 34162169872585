<template>
  <div>
    <h1 class="main-header-h1">自动生成-视频-作品管理</h1>
    <!--搜索区域-->
    <el-form ref="queryFormRef" :model="queryParams" :inline="true" label-width="68px">
      <el-form-item label="用户名：" prop="platformUserId">
        <el-select v-model="queryParams.platformUserId" style="width: 300px" placeholder="请选择用户名" clearable>
          <el-option
              v-for="account in accountInfoList"
              :key="account.id"
              :disabled="account.status != 1"
              :label="`${account.platform_user_nickname} (${account.status == 1 ? '有效' : '无效'})`"
              :value="account.platform_user_id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="备注：" prop="remark">
        <el-input v-model="queryParams.remark" placeholder="备注" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="fetchLumaWorkInfo" :loading="loading">
          <el-icon>
            <Search/>
          </el-icon>
          <span>搜索</span>
        </el-button>
        <el-button type="success" @click="addLumaWork">
          <el-icon>
            <CirclePlusFilled/>
          </el-icon>
          <span>创建作品</span>
        </el-button>
      </el-form-item>
    </el-form>
    <!--表格数据-->
    <el-table :data="cachedTableData">
      <el-table-column prop="id" width="80" label="ID"></el-table-column>
      <!--      <el-table-column prop="platform_type" width="100" label="平台类型"></el-table-column>-->
      <!--      <el-table-column prop="platform_user_id" width="100" label="平台用户ID"/>-->
      <!--      <el-table-column prop="platform_user_nickname" width="100" label="平台用户名"></el-table-column>-->
      <!--      <el-table-column prop="platform_work_id" width="100" label="平台作品ID"/>-->
      <el-table-column prop="content" width="500" label="Prompt">
        <template #default="scope">
          <el-tooltip class="item" effect="dark" :content="scope.row.content" placement="top">
            <span>{{
                scope.row.content.length > 60 ? scope.row.content.substring(0, 60) + '...' : scope.row.content
              }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <!--      <el-table-column prop="meta_data" width="300" label="作品信息"></el-table-column>-->
      <el-table-column prop="create_time" label="创建时间"/>
      <el-table-column prop="work_status" label="作品状态">
        <template #default="scope">
          <el-tag :type="getTagType(scope.row.work_status)" disable-transitions>
            {{ getStatusText(scope.row.work_status) }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="oss_url" label="作品视频">
        <template #default="scope">
<!--          <video-player v-show="scope.row.oss_url" :key="scope.row.id" :video-url="scope.row.oss_url"></video-player>-->
          <el-button v-if="scope.row.oss_url" type="primary" @click="handleView(scope.row.oss_url)">查看</el-button>
          <el-button v-show="!scope.row.oss_url" link type="warning" disabled>暂无</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注">
        <template #default="scope">
          <el-input :rows="2"
                    type="textarea" v-model="scope.row.remark" @change="editRemark(scope.row)"
                    placeholder="输入备注"></el-input>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="120">
        <template #default="scope">
          <div style="display: flex; flex-direction: column; align-items: flex-start;">
            <el-button link disabled></el-button>
            <el-button style="margin-bottom: 5px;" link type="primary" @click="clickReGenerateWork(scope.row)">
              重新制作
            </el-button>
            <el-button style="margin-bottom: 5px;" link type="success" @click="clickAddMaterial(scope.row)">
              转为素材
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--分页数据-->
    <pagination v-show="total > 0" :total="total" v-model:page="queryParams.pageNum"
                v-model:limit="queryParams.pageSize" @pagination="fetchLumaWorkInfo"/>
    <!--创建作品-->
    <el-dialog v-if="showAddLumaWorkDialog"
               v-model="showAddLumaWorkDialog"
               title="创建作品"
               @opened="handleClickCreateWork"
               width="40%"
               :close-on-click-modal="false">
      <el-form
          :model="userInfoForm"
          :rules="rules"
          ref="addForm"
          label-position="left"
          label-width="auto"
      >
        <el-form-item label="用户名：" prop="platformUserId" required>
          <el-select v-model="userInfoForm.platformUserId" placeholder="请选择用户" clearable>
            <el-option
                v-for="account in accountInfoList"
                :key="account.id"
                :disabled="account.status != 1"
                :label="`${account.platform_user_nickname} (${account.status == 1 ? '有效' : '无效'})`"
                :value="account.platform_user_id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="提示词：" prop="prompt">
          <el-input :rows="4"
                    type="textarea"
                    placeholder="必填，请输入提示语"
                    v-model="userInfoForm.prompt"/>
        </el-form-item>
        <el-form-item label="推荐图片：">
          <div class="photo-wall">
            <div
                v-for="(imageUrl, index) in recommendedImages"
                :key="index"
                class="photo-item"
                :class="{ selected: userInfoForm.ossUrl === imageUrl }"
                @click="selectImage(imageUrl)"
                @mouseenter="hoverImage(imageUrl)"
                @mouseleave="leaveImage"
            >
              <img :src="imageUrl" alt="推荐图片"/>
              <div v-if="hoveredImageUrl === imageUrl" class="actions">
                <span @click.stop="previewImage(imageUrl)">预览</span>
                <span @click.stop="selectImage(imageUrl)">选中</span>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="上传图片：" prop="file_url">
          <el-upload
              ref="upload"
              :action="uploadUrl"
              :on-success="handleUploadSuccess"
              :file-list="fileList"
              :limit="1"
              :on-exceed="handleExceed"
              :before-upload="beforeUpload"
              list-type="picture-card"
              accept="image/*"
              :on-preview="handlePictureCardPreview"
              :http-request="uploadToOss">
            <el-button type="primary" link>
              <el-icon>
                <CirclePlusFilled/>
              </el-icon>
              点击上传
            </el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
              :rows="2"
              type="textarea"
              placeholder="可选，备注"
              v-model="userInfoForm.remark"/>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="info" @click="showAddLumaWorkDialog = false">取消</el-button>
          <el-button type="primary" @click="submitForm('userInfoForm')" :loading="submitLoading">提交</el-button>
        </div>
      </template>
    </el-dialog>
    <!--点击查看-->
    <el-dialog title="查看作品" v-model="dialogVisible" destroy-on-close :width="'60%'">
      <template v-if="isVideo(filePathOrUrl)">
        <div style="width: 100%">
          <video width="100%" style="max-height: 600px;" controls>
            <source :src="filePathOrUrl" type="video/mp4">
            您的浏览器不支持视频播放。
          </video>
        </div>
      </template>
      <template v-else>
        <img :src="filePathOrUrl" alt="图片预览" style="width: 100%; max-height: 100%;">
      </template>
    </el-dialog>
    <!--添加素材-->
    <el-dialog title="添加素材" v-model="addMaterialDialogVisible" destroy-on-close :width="'40%'"
               :close-on-click-modal="false">
      <el-form
          :model="addMaterialForm"
          :rules="addMaterialRules"
          ref="addMaterialInfoForm"
          label-position="left"
          label-width="auto">
        <el-form-item label="素材名称：" prop="name">
          <el-input
              placeholder="必填，请输入素材名称"
              v-model="addMaterialForm.name"/>
        </el-form-item>
        <el-form-item label="素材阶段：" prop="levelType">
          <el-radio-group v-model="addMaterialForm.levelType">
            <el-radio value="raw">原始</el-radio>
            <el-radio value="pre">预处理</el-radio>
            <el-radio value="mid">中间物</el-radio>
            <el-radio value="end">成品</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="素材标签：" prop="allTags" required>
          <div class="tag-container">
            <div class="tag-flex tag-gap-2">
              <el-check-tag
                  v-for="tag in tags"
                  type="primary"
                  :checked="tag.checked"
                  @change="clickTag(tag)"
                  :key="tag">
                {{ tag.name }}
              </el-check-tag>
            </div>
            <div class="custom-tag-input">
              <el-input
                  v-model="newTag"
                  style="max-width: 200px"
                  placeholder="新增自定义标签"
                  class="input-with-select">
                <template #append>
                  <el-button @click="addCustomTag" :loading="addTagLoading">
                    <el-icon>
                      <Position/>
                    </el-icon>
                  </el-button>
                </template>
              </el-input>
            </div>
          </div>
        </el-form-item>

        <el-form-item label="素材描述：" prop="description">
          <el-input
              :rows="2"
              type="textarea"
              placeholder="可选，素材描述"
              v-model="addMaterialForm.description"/>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="info" @click="addMaterialDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="submitAddMaterialForm('addMaterialInfoForm')" :loading="submitLoading">
            提交
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axiosInstance from '@/api/axiosInstance';
import {CirclePlusFilled, Search, Download, Plus, Position} from '@element-plus/icons-vue';
import Pagination from "@/components/pagination/index.vue";
import axios from 'axios';
import VideoPlayer from "@/components/VideoPlayer.vue";

export default {
  name: "luma-account",
  components: {
    VideoPlayer,
    Pagination,
    CirclePlusFilled, Search, Download, Plus, Position
  },
  computed: {
    cachedTableData() {
      return this.workInfoList;
    }
  },
  data() {
    const validateCookie = (rule, value, callback) => {
      if (!value) {
        callback(new Error('Cookie不能为空'));
      }
      callback();
    };
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 20,
        platformUserId: "",
        platformUserNickname: "",
        remark: ""
      },
      newTag: "",
      tagQueryParams: {
        pageNum: 1,
        pageSize: 40,
      },
      accountInfoList: [],
      reportTableData: [],
      loading: false,
      total: 0,
      loginRecord: {},
      workInfoList: [],
      showAddLumaWorkDialog: false,
      fileList: [],
      uploadUrl: "",
      loginId: '',
      activeStep: 0,
      loginStatus: '',
      verifyCode: '',
      qrCodeRemainingTime: 0,
      autoWorkTimer: null,
      submitLoading: false,
      userInfoForm: {
        platformUserId: '',
        prompt: '',
        imageUrl: '',
        ossUrl: '',
        remark: '',
      },
      addTagLoading: false,
      tags: [
        {
          'code': 'TAG2OEAR',
          'name': "服装行业",
          'checked': false,
        }
      ],
      addMaterialForm: {
        name: '', // 素材名称
        levelType: 'mid', // 素材阶段，原始 raw；预处理 pre；中间物 mid；成品 end
        type: 'video', // 素材类型，图片：image；视频：video；音频 audio；文案 text
        tags: [],
        description: '', // 素材描述
        ossUrl: '',
      },
      rules: {
        platformUserId: [{required: true, message: '平台用户是必填项', trigger: 'blur'}],
        prompt: [{required: true, message: '提示语是必填项', trigger: 'blur'}],
        // file_url: [{required: true, message: '请上传一张图片', trigger: 'change'}]
      },
      addMaterialRules: {
        name: [{required: true, message: '请输入素材名称', trigger: 'blur'}],
        // allTags: [{required: true, message: '请输入选择素材标签', trigger: 'blur'}],
        levelType: [{required: true, message: '请选择素材阶段', trigger: 'blur'}],
      },
      dialogVisible: false,
      addMaterialDialogVisible: false,
      dialogImageUrl: "",
      filePathOrUrl: '',
      recommendedImages: [],
      selectedImageUrl: "",
      hoveredImageUrl: ""
    };
  },
  created() {
    if (this.$route.query.platformUserId) {
      this.queryParams.platformUserId = this.$route.query.platformUserId
    }
    console.log('created', this.$route.query.platformUserId, this.queryParams.platformUserId)
    this.fetchLumaWorkInfo();
    // this.initUploadUrl();
  },
  mounted() {
    this.fetchLumaAccountInfo();
    this.startAutoWorkTimer();
    this.fetchTagInfos();
  },
  methods: {
    // 新增素材
    submitAddMaterialForm(formName) {
      let that = this;
      this.addMaterialForm.tags = []
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.tags.forEach(tag => {
            if (tag.checked) {
              this.addMaterialForm.tags.push(tag)
            }
          })
          if (this.addMaterialForm.tags.length === 0) {
            this.$message.error('请至少选择一个标签');
            return false;
          }
          // 提交表单逻辑
          console.log('表单验证通过，可以提交数据', this.addMaterialForm);
          that.submitLoading = true;
          // 用户点击了确定按钮
          axiosInstance.post(`/api/material/create`, this.addMaterialForm).then(response => {
            that.submitLoading = false;
            if (response.data && response.data.code === 0) {
              that.$message({
                type: 'success',
                message: '提交成功，可在素材管理中查看!'
              });
              that.addMaterialDialogVisible = false;
              that.clearAddMaterialForm();
            } else {
              that.$message({
                type: 'error',
                message: '提交失败: ' + response.data.msg
              });
            }
          }).catch(error => {
            that.submitLoading = false;
            that.$message({
              type: 'error',
              message: '删除用户信息失败: ' + error.message
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 查询tag
    fetchTagInfos() {
      axiosInstance.post('/api/tag/list', this.tagQueryParams)
          .then(response => {
            if (response.data && response.data.code === 0) {
              this.tags = response.data.data.results;
              this.tags.forEach(tag => {
                tag.checked = false;
              })
              console.log("this.allTags", this.tags)
            } else {
              this.$message.error(response.data.msg);
            }
          }).catch(error => {
        console.error(error)
      });
    },
    clearAddMaterialForm() {
      this.addMaterialForm.ossUrl = "";
      this.addMaterialForm.name = "";
      this.addMaterialForm.tags = [];
      this.addMaterialForm.description = "";
    },
    // 新增tag
    addCustomTag() {
      this.addTagLoading = true;
      axiosInstance.post('/api/tag/create', {
        "name": this.newTag,
        "type": "custom",
      }).then(response => {
        this.addTagLoading = false;
        if (response.data && response.data.code === 0) {
          this.$message.success("添加成功");
          this.newTag = "";
          this.fetchTagInfos();
        } else {
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        this.addTagLoading = false;
        console.error(error)
      });
    },
    clickTag(tag) {
      console.log("clickTag e", tag)
      tag.checked = !tag.checked;
    },
    parseMetaData(metaData) {
      try {
        return JSON.parse(metaData);
      } catch (e) {
        return {};
      }
    },
    initUploadUrl() {
      this.uploadUrl = process.env.VUE_APP_BASE_URL + "/api/luma/upload_image"
      console.log("uploadVideoUrl:", this.uploadUrl)
    },
    fetchLumaWorkInfo() {
      this.loading = true
      axiosInstance.post('/api/luma/work/list', this.queryParams)
          .then(response => {
            if (response.data && response.data.code === 0) {
              this.workInfoList = response.data.data.results;
              this.total = response.data.data.total;
            } else {
              this.$message.error(response.data.msg);
            }
            this.loading = false
          }).catch(error => {
        this.loading = false
        console.error(error)
      });
    },
    addLumaWork() {
      this.showAddLumaWorkDialog = true;
    },
    clickReGenerateWork(row) {
      this.showAddLumaWorkDialog = true;
      this.userInfoForm.platformUserId = row.platform_user_id;
      this.userInfoForm.prompt = row.content;
      this.userInfoForm.remark = row.remark;
    },
    clickAddMaterial(row) {
      this.addMaterialDialogVisible = true;
      this.addMaterialForm.ossUrl = row.oss_url;
      this.fetchTagInfos();
    },
    getTagType(status) {
      switch (status) {
        case 'pending':
          return 'info';
        case 'processing':
          return 'primary';
        case 'completed':
          return 'success';
        case 'auto_downloading':
          return 'warning';
        case 'auto_download_failed':
          return 'danger';
        case 'auto_upload_oss_failed':
          return 'danger';
        case 'auto_finished':
          return 'success';
        case 'deleted':
          return 'danger';
        default:
          return '';
      }
    },
    getStatusText(status) {
      switch (status) {
        case 'pending':
          return '制作排队中';
        case 'processing':
          return '制作中';
        case 'completed':
          return '制作完成';
        case 'can_not_download':
          return '作品无法下载';
        case 'auto_downloading':
          return '作品自动下载中';
        case 'auto_download_failed':
          return '作品自动下载失败';
        case 'auto_upload_oss_failed':
          return '作品上传OSS失败';
        case 'auto_finished':
          return '作品处理完成';
        case 'deleted':
          return '已删除';
        default:
          return '未知状态';
      }
    },
    handleView(url) {
      this.filePathOrUrl = url;
      this.dialogVisible = true;
    },
    editRemark(row) {
      const payload = {
        id: row.id,  // Assuming each row has a unique identifier
        remark: row.remark
      };
      axiosInstance.post('/api/luma/work/edit_remark', payload)
          .then(response => {
            if (response.data && response.data.code === 0) {
              this.$message.success('备注更新成功');
            } else {
              this.$message.error(response.data.msg);
            }
          })
          .catch(error => {
            console.error(error);
            this.$message.error('备注更新失败');
          });
    },
    isVideo(url) {
      try {
        const parsedUrl = new URL(url);
        const videoExtensions = ['.mp4', '.avi', '.mov', '.mkv', '.flv', '.wmv'];
        return videoExtensions.some(ext => parsedUrl.pathname.endsWith(ext));
      } catch (e) {
        console.error('Invalid URL:', url, e);
        return false;
      }
    },
    uploadToOss({file}) {
      axios.put(this.uploadUrl, file, {
        headers: {
          'Content-Type': 'image/*',
        }
      }).then(response => {
        console.info("response", response)
        this.handleUploadSuccess(response.data, file, this.fileList);
      }).catch(error => {
        this.fileList = []
        this.$message.error('上传失败');
        console.error(error);
      });
    },
    handleUploadSuccess(response, file, fileList) {
      // if (response.code === 0 && response.data.file_url) {
      //   this.$message.success('图片上传成功');
      //   this.addForm.imageUrl = response.data.file_url;  // 假设服务器返回图片的URL
      // } else {
      //   this.$message.error('图片上传失败');
      // }
      this.userInfoForm.ossUrl = this.uploadUrl
      this.$message.success('上传成功');
      this.fileList = fileList;
    },
    handleExceed(files, fileList) {
      this.$message.warning('只能上传一张图片');
    },
    async beforeUpload(file) {
      const isImage = file.type.startsWith('image/');
      if (!isImage) {
        this.$message.error('只能上传图片文件');
        return false
      }
      // if (!this.addForm.platformUserId) {
      //   this.$message.error('请先选择用户');
      //   return false
      // }
      try {
        const response = await axiosInstance.post(`/api/presigned_url_for_oss`, {
          filename: file.name,
          method: 'put',
          platformUserId: this.userInfoForm.platformUserId,
          platformType: 'luma',
        })
        console.log(response)
        if (response.data.code === 0) {
          this.uploadUrl = response.data.data.oss_url;
          return true;
        } else {
          this.$message.error('获取上传URL失败');
          return false;
        }
      } catch (error) {
        console.error(error);
        this.$message.error('获取上传URL失败');
        return false;
      }
    },
    handlePictureCardPreview(file) {
      this.filePathOrUrl = file.url;
      this.dialogVisible = true;
    },
    submitForm(formName) {
      let that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 提交表单逻辑
          console.log('表单验证通过，可以提交数据', this.userInfoForm);
          that.submitLoading = true;
          // 用户点击了确定按钮
          axiosInstance.post(`/api/luma/work/generate`, this.userInfoForm).then(response => {
            that.submitLoading = false;
            if (response.data && response.data.code === 0) {
              that.$message({
                type: 'success',
                message: '提交成功，请及时查看作品状态!'
              });
              that.showAddLumaWorkDialog = false
              // 刷新作平信息列表
              that.fetchLumaWorkInfo();
            } else {
              that.$message({
                type: 'error',
                message: '提交失败: ' + response.data.msg
              });
            }
          }).catch(error => {
            that.submitLoading = false;
            that.$message({
              type: 'error',
              message: '删除用户信息失败: ' + error.message
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    fetchLumaAccountInfo() {
      let queryParamsNew = {...this.queryParams};
      queryParamsNew.pageSize = 200
      axiosInstance.post('/api/luma/account/list', queryParamsNew)
          .then(response => {
            if (response.data && response.data.code === 0) {
              this.accountInfoList = response.data.data.results;
              this.total = response.data.data.total;
            } else {
              this.$message.error(response.data.msg);
            }
          }).catch(error => {
        console.error(error)
      });
    },

    startAutoWorkTimer() {
      this.autoWorkTimer = setInterval(() => {
        // this.fetchLoginRecord();
        this.fetchLumaWorkInfo();
      }, 10000);
      console.log("自动获取luma作品已开启...")
    },

    stopAllTimer() {
      console.log('stopAllTimer')
      if (this.autoWorkTimer) {
        clearInterval(this.autoWorkTimer);
      }
    },

    handleClickCreateWork() {
      this.fetchRecommendedImages();
    },

    fetchRecommendedImages() {
      try {
        axiosInstance.get('/api/luma/recommended_images').then(response => {
          if (response.data.code === 0) {
            this.recommendedImages = response.data.data;
          } else {
            this.$message.error('获取推荐图片失败');
          }
        })
      } catch (error) {
        console.error(error);
        this.$message.error('获取推荐图片失败');
      }
    },

    selectImage(imageUrl) {
      this.userInfoForm.ossUrl = imageUrl;
    },

    hoverImage(imageUrl) {
      this.hoveredImageUrl = imageUrl;
    },
    leaveImage() {
      this.hoveredImageUrl = null;
    },
    previewImage(imageUrl) {
      this.filePathOrUrl = imageUrl;
      this.dialogVisible = true;
    },

  },
  beforeUnmount() {
    this.stopAllTimer();
  }
};
</script>

<style scoped>
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 20px;
}

.main-header-h1 {
  text-align: center;
  color: #333;
}

#button-container {
  text-align: center;
  margin-bottom: 20px;
}

.add-user-button {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-user-button:hover {
  background-color: #0056b3;
}

#cookie-info-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.cookie-info {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.cookie-info h2 {
  margin: 0 0 10px;
  font-size: 1.5em;
  font-weight: 600;
  color: #333;
}

.cookie-info span {
  margin-bottom: 5px;
  font-size: 1em;
  color: #555;
}

.cookie-status-valid {
  color: green;
}

.cookie-status-invalid {
  color: red;
}

.step-content {
  text-align: center;
  margin-top: 20px;
}

.highlight {
  color: red;
  font-weight: bold;
}

.photo-wall {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-height: 200px;
  overflow: auto;
}

.photo-item {
  width: calc(33.33% - 10px);
  cursor: pointer;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.photo-item img {
  width: 100%;
  height: auto;
}

.photo-item.selected {
  border: 2px solid #409eff;
}

.actions {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  background: rgba(0, 0, 0, 0.5);
  color: #d9ecff;
  padding: 5px 0;
}

.actions span {
  cursor: pointer;
}

.tag-container {
  display: flex;
  flex-direction: column;
}

.tag-flex {
  display: flex;
  flex-wrap: wrap;
}

.tag-gap-2 {
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.custom-tag-input {
  margin-top: 1rem; /* 增加顶部边距，以确保与上方标签列表之间有间距 */
}
</style>