// src/api/axiosInstance.js

import axios from 'axios';
import {ElMessage} from 'element-plus';
import router from '@/router';
import store from '@/store'; // 导入 Vuex store


// 创建 axios 实例
const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL, // 设置基础 URL
    timeout: 300000, // 设置超时时间 5分钟
});

// 请求拦截器
instance.interceptors.request.use(config => {
    // 在请求发送前可以做一些处理，例如添加 JWT token 到请求头
    const token = localStorage.getItem('token');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, error => {
    // 处理请求错误
    return Promise.reject(error);
});

// 响应拦截器
instance.interceptors.response.use(response => {
    // 处理响应数据
    return response;
}, error => {
    // 处理响应错误
    if (error.response) {
        if (error.response.status === 401) {
            // 清空本地存储的数据
            localStorage.clear();
            // // 调用 Vuex 的 logout 动作来清除登录数据
            store.dispatch('logout');
            ElMessage.error('请先登录');
            // // 如果后端返回 401 未授权，跳转到登录页面
            // router.push('/login');
        } else if (error.response.status === 403) {
            ElMessage.error('金币不足，请充值');
        }
        console.log(`Error: ${error.response.status} - ${error.response.data.message}`);
        // ElMessage.error(`Error: ${error.response.status} - ${error.response.data.message}`);
    } else if (error.request) {
        console.error('Request error:', error.request)
        // ElMessage.error('Network Error');
    } else {
        console.error('Error:', error.message);
        // ElMessage.error(`Error: ${error.message}`);
    }
    return Promise.reject(error);
});

export default instance;