<template>
  <div>
    <video
        ref="videoPlayer"
        :src="videoUrl"
        controls
        @click="toggleFullScreen"
        style="width: 100%; cursor: pointer;"
    ></video>
  </div>
</template>

<script>
export default {
  name: 'VideoPlayer',
  props: {
    videoUrl: {
      type: String,
      required: true
    }
  },
  mounted() {
    console.log("VideoPlayer mounted: ", this.videoUrl)
  },
  methods: {
    toggleFullScreen() {
      const video = this.$refs.videoPlayer;
      if (video.requestFullscreen) {
        video.requestFullscreen();
      } else if (video.mozRequestFullScreen) { /* Firefox */
        video.mozRequestFullScreen();
      } else if (video.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        video.webkitRequestFullscreen();
      } else if (video.msRequestFullscreen) { /* IE/Edge */
        video.msRequestFullscreen();
      }
    }
  }
};
</script>

<style scoped>
.el-card {
  padding: 20px;
}
</style>
