<template>
  <div>
    <!--搜索区域-->
    <el-form ref="queryFormRef" :model="queryParams" :inline="true" label-width="68px">
      <el-form-item label="任务名" prop="taskName">
        <el-input v-model="queryParams.taskName" placeholder="任务名，支持模糊查询" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="queryVideoMixTaskList">
          <el-icon>
            <Search/>
          </el-icon>
          <span>搜索</span>
        </el-button>
        <!--        <el-button type="success" @click="handleAdd">-->
        <!--          <el-icon>-->
        <!--            <CirclePlusFilled/>-->
        <!--          </el-icon>-->
        <!--          <span>添加场景配置</span>-->
        <!--        </el-button>-->
      </el-form-item>
    </el-form>
    <!--表格数据-->
    <el-table :data="tableInfoList" v-loading="loading" @selection-change="handleSelectionChange">
      <!--      <el-table-column type="selection" width="55"/>-->
      <el-table-column prop="config_name" width="200" label="场景配置名"></el-table-column>
      <el-table-column prop="task_name" label="任务名"/>
      <el-table-column prop="task_priority" width="100" label="任务优先级"/>
      <el-table-column width="120" label="素材标签">
        <template #default="scope">
          <span>{{scope.row.meta_data.output_tag_name}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="produced_video_num" label="已生产视频数" width="120">
        <template #default="scope">
          <el-button :disabled="scope.row.produced_video_num <= 0" type="primary" link
                     @click="handleClickProductVideo(scope.row)">{{ scope.row.produced_video_num }}
          </el-button>
        </template>
      </el-table-column>
<!--      <el-table-column width="120" label="最大可生产数">-->
<!--        <template #default="scope">-->
<!--          <span>{{scope.row.meta_data.max_video_num}}</span>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column width="120" label="预期视频数">
        <template #default="scope">
          <span>{{scope.row.meta_data.expect_video_num}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="begin_time" width="120" label="任务开始时间"/>
      <el-table-column prop="end_time" width="120" label="任务结束时间"/>
      <el-table-column prop="task_status" width="80" label="任务状态">
        <template #default="scope">
          <el-tag v-if="scope.row.task_status === 'queuing'" type="info">待执行</el-tag>
          <el-tag v-if="scope.row.task_status === 'doing'">执行中</el-tag>
          <el-tag v-if="scope.row.task_status === 'done_success'" type="success">执行成功</el-tag>
          <el-tag v-if="scope.row.task_status === 'done_fail'" type="danger">执行出错</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="task_message" width="200" label="执行说明"/>
      <el-table-column prop="create_time" width="120" label="创建时间"/>
      <el-table-column prop="update_time" width="120" label="更新时间"/>
      <el-table-column prop="remark" width="200" label="备注"></el-table-column>
      <el-table-column fixed="right" label="操作" width="160">
        <template #default="scope">
          <div style="display: flex; flex-direction: column; align-items: flex-start;">
            <el-button link disabled></el-button>
            <el-button style="margin-bottom: 5px;" link type="primary" @click.prevent="handleExec(scope.row)">执行
            </el-button>
            <!--            <el-button style="margin-bottom: 5px;" link type="danger" @click.prevent="handleDelete(scope.row)">-->
            <!--              删除-->
            <!--            </el-button>-->
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--分页数据-->
    <pagination v-show="total > 0" :total="total" v-model:page="queryParams.pageNum"
                v-model:limit="queryParams.pageSize" @pagination="queryVideoMixTaskList"/>
    <!--重新执行任务-->
    <el-dialog title="执行视频生产"
               v-model="showExecTaskDialog"
               width="40%"
               :close-on-click-modal="false">
      <el-form
          :model="addForm"
          ref="addForm"
          label-position="left"
          label-width="auto"
          style="max-width: 600px"
      >
        <el-form-item label="预期视频数量：" prop="expectVideoNum">
          <el-input-number placeholder="请输入预期生成视频数量" v-model="expectVideoNum" :min="1" :max="200"/>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input placeholder="可选，备注" v-model="remark"/>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="handleCancelRedo">取消</el-button>
          <el-button type="primary" @click="execTask" :loading="submitLoading">提交</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axiosInstance from '@/api/axiosInstance';
import {CirclePlusFilled, Search, Download, Check} from '@element-plus/icons-vue';
import Pagination from "@/components/pagination/index.vue";

export default {
  name: "luma-account",
  components: {
    Pagination,
    CirclePlusFilled, Search, Download, Check
  },
  computed: {
    isLogin() {
      return this.$store.state.isLogin;
    },
    isSuperAdmin() {
      return this.$store.state.isSuperAdmin;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },

  data() {
    const validateCookie = (rule, value, callback) => {
      if (!value) {
        callback(new Error('Cookie不能为空'));
      }
      callback();
    };
    return {
      selectedIds: [],
      queryParams: {
        tenantId: '',
        pageNum: 1,
        pageSize: 20,
        taskId: "",
        taskName: "",
      },
      reportTableData: [],
      loading: false,
      total: 0,

      loginRecord: {},
      tableInfoList: [],
      showExecTaskDialog: false,
      loginId: '',
      activeStep: 0,
      loginStatus: '',
      verifyCode: '',
      qrCodeRemainingTime: 0,
      cookieTimer: null,
      submitLoading: false,
      taskId: "",
      expectVideoNum: 1,
      remark: "",
    };
  },
  created() {
    if (this.$route.query.taskId) {
      this.queryParams.taskId = this.$route.query.taskId
    }
    this.queryVideoMixTaskList();
  },
  mounted() {
  },
  methods: {
    handleSelectionChange(rows) {
      console.log("handleSelectionChange", rows)
      // rows 对象数组 取id字段 转对应数组
      this.selectedIds = rows.map(item => item.id) || [];
    },
    handleClickProductVideo(row) {
      let path = `/content/material?tag=${row.meta_data.output_tag_name}`;
      this.$router.push(path);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.execTask();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleCancelRedo() {
      this.submitLoading = false;
      this.showExecTaskDialog = false;
    },
    execTask() {
      this.submitLoading = true;
      axiosInstance.post('/api/video-mix/task/run/redo', {
        "taskId": this.taskId,
        "expectVideoNum": this.expectVideoNum,
        "remark": this.remark,
      }).then(response => {
        if (response.data && response.data.code === 0) {
          this.$message.success("执行成功");
        } else {
          this.$message.error("执行失败, 请稍后再试");
        }
        this.submitLoading = false;
        this.showExecTaskDialog = false;
        this.queryVideoMixTaskList();
      }).catch(error => {
        console.error(error)
        this.submitLoading = false;
        this.showExecTaskDialog = false;
      });
    },
    queryVideoMixTaskList() {
      this.loading = true
      axiosInstance.post('/api/video-mix/task/list', this.queryParams).then(response => {
        if (response.data && response.data.code === 0) {
          this.tableInfoList = response.data.data.results;
          this.total = response.data.data.total;
        } else {
          this.$message.error(response.data.msg);
        }
        this.loading = false
      }).catch(error => {
        this.loading = false
        console.error(error)
      });
    },
    handleExec(row) {
      this.expectVideoNum = row.expect_video_num;
      this.taskId = row.task_id;
      this.showExecTaskDialog = true;
      console.log("handleExec", this.expectVideoNum, this.taskId)
    },
  },
};
</script>

<style scoped>
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 20px;
}

.main-header-h1 {
  text-align: center;
  color: #333;
}

#button-container {
  text-align: center;
  margin-bottom: 20px;
}

.add-user-button {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-user-button:hover {
  background-color: #0056b3;
}

#cookie-info-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.cookie-info {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.cookie-info h2 {
  margin: 0 0 10px;
  font-size: 1.5em;
  font-weight: 600;
  color: #333;
}

.cookie-info span {
  margin-bottom: 5px;
  font-size: 1em;
  color: #555;
}

.cookie-status-valid {
  color: green;
}

.cookie-status-invalid {
  color: red;
}

.step-content {
  text-align: center;
  margin-top: 20px;
}

.highlight {
  color: red;
  font-weight: bold;
}
</style>