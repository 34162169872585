<template>
  <div>
    <!--    <h1 class="main-header-h1">抖音作品管理</h1>-->
    <!--搜索区域-->
    <el-form ref="queryFormRef" :model="queryParams" :inline="true" label-width="100px">
      <el-form-item v-if="isSuperAdmin" label="租户：" prop="tenantId">
        <el-select v-model="queryParams.tenantId" style="width: 300px" placeholder="请选择租户" clearable>
          <el-option
              v-for="tenant in tenantInfoList"
              :key="tenant.tenantId"
              :label="`${tenant.tenantName} (${tenant.status == 1 ? '有效' : '无效'})`"
              :value="tenant.tenantId"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="抖音用户：" prop="platformUserId">
        <el-select v-model="queryParams.platformUserId" style="width: 300px" placeholder="请选择抖音用户" clearable>
          <el-option
              v-for="account in accountInfoList"
              :key="account.id"
              :label="`${account.platform_user_nickname} (${account.status == 1 ? '有效' : '无效'})`"
              :value="account.platform_user_id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="发布状态：" prop="workStatus">
        <el-select v-model="queryParams.workStatus" style="width: 200px" placeholder="请选择发布状态" clearable>
          <el-option label="待发布" value="publish_ready"/>
          <el-option label="发布中" value="publishing"/>
          <el-option label="等待验证码" value="wait_verify_code"/>
          <el-option label="发布失败" value="publish_failed"/>
          <el-option label="发布成功" value="publish_success"/>
        </el-select>
      </el-form-item>
      <el-form-item label="抖音平台作品状态：" prop="platformWorkStatus" label-width="200px">
        <el-select v-model="queryParams.platformWorkStatus" style="width: 200px" placeholder="请选择作品状态" clearable>
          <el-option label="审核中" value="publish_auditing"/>
          <el-option label="定时发布中" value="publish_timing"/>
          <el-option label="已发布" value="publish_success"/>
          <el-option label="未通过" value="publish_failed"/>
        </el-select>
      </el-form-item>
      <el-form-item label="备注：" prop="remark">
        <el-input v-model="queryParams.remark" placeholder="备注" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="openPreview" style="margin-right: 20px" label="开启预览" size="large"/>
        <el-button type="primary" @click="fetchDouyinWorkInfo" :loading="loading">
          <el-icon>
            <Search/>
          </el-icon>
          <span>搜索</span>
        </el-button>
        <el-button type="success" @click="publishDouyinVideo">
          <el-icon>
            <CirclePlusFilled/>
          </el-icon>
          <span>发布视频</span>
        </el-button>
        <el-button type="warning"
                   :disabled="selectedWorkIds === null || selectedWorkIds.length === 0"
                   @click="clickRePublish">
          <el-icon>
            <Check/>
          </el-icon>
          <span>重新发布</span>
        </el-button>
      </el-form-item>
    </el-form>
    <!--表格数据-->
    <el-table :data="workInfoList" v-loading="loading" @selection-change="handleTableSelectionChange">
      <el-table-column type="selection" width="55"/>
      <!--      <el-table-column prop="id" width="50" label="ID"></el-table-column>-->
      <el-table-column prop="platform_user_nickname" width="100" label="抖音昵称"></el-table-column>
      <el-table-column prop="platform_user_id" label="抖音ID"/>
      <el-table-column prop="video_title" label="作品标题" width="200"></el-table-column>
      <el-table-column prop="video_desc" label="作品描述" width="100"></el-table-column>
      <el-table-column prop="video_tags" label="作品话题" width="200"></el-table-column>
      <el-table-column prop="oss_url" width="300" label="作品视频">
        <template #default="scope">
          <div v-if="openPreview">
            <video-player v-if="scope.row.oss_url" :key="scope.row.id" :video-url="scope.row.oss_url"></video-player>
            <el-button v-else link type="warning" disabled>暂无</el-button>
          </div>
          <div v-else>
            <el-button link type="info" disabled>未开启预览</el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="work_status" label="发布状态">
        <template #default="scope">
          <el-tag :type="getTagType(scope.row.work_status)" disable-transitions>
            {{ getStatusText(scope.row.work_status) }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="work_status_message" label="发布说明" width="150"></el-table-column>
      <el-table-column prop="platform_work_status" label="抖音平台作品状态" width="200">
        <template #default="scope">
          <div>
            <el-tag :type="getPlatformWorkTagType(scope.row.platform_work_status)" disable-transitions>
              {{ getPlatformWorkStatusText(scope.row.platform_work_status) }}
            </el-tag>
            <br>
            <p>发布时间：{{ JSON.parse(scope.row.meta_data).publish_time }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="platform_work_url" label="抖音平台作品链接" width="200">
        <template #default="scope">
          <div>{{ 'https://www.douyin.com/video/' + scope.row.platform_work_id }}</div>
          <el-button type="text" @click="gotoDouyinWorkUrl(scope.row.platform_work_id)">点击前往</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" width="200"/>
      <el-table-column prop="remark" label="备注" width="200">
        <template #default="scope">
          <el-input :rows="2"
                    type="textarea" v-model="scope.row.remark" @change="editRemark(scope.row)"
                    placeholder="输入备注"></el-input>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="120">
        <template #default="scope">
          <div style="display: flex; flex-direction: column; align-items: flex-start;">
            <el-button v-if="scope.row.work_status === 'publish_failed'" style="margin-bottom: 5px;"
                       type="warning"
                       @click="clickRePublish(scope.row)">重新发布
            </el-button>
            <el-button v-if="scope.row.platform_work_status === 'publish_success'" style="margin-bottom: 5px;"
                       type="primary"
                       @click="clickSeeWorkData(scope.row)">作品数据
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--分页数据-->
    <pagination v-show="total > 0" :total="total" v-model:page="queryParams.pageNum"
                v-model:limit="queryParams.pageSize" @pagination="fetchDouyinWorkInfo"/>

    <!--发布视频-->
    <el-dialog v-if="showPublishVideoDialog"
               v-model="showPublishVideoDialog"
               title="发布视频"
               width="60%"
               destroy-on-close
               :close-on-click-modal="false">
      <el-form
          :model="workInfoForm"
          :rules="rules"
          ref="workInfoForm"
          label-position="left"
          label-width="auto"
      >
        <el-form-item label="抖音用户（必填）：：" prop="platformUserIds">
          <el-select multiple v-model="workInfoForm.platformUserIds"
                     placeholder="请选择用户"
                     clearable
                     @change="handleSelectDyUserChange">
            <!-- 全选项 -->
            <el-option
                :key="allSelectedOption.id"
                :label="allSelectedOption.label"
                :value="allSelectedOption.value"
            />
            <el-option
                v-for="account in accountInfoList"
                :key="account.id"
                :disabled="account.status != 1"
                :label="`${account.platform_user_nickname} (${account.status == 1 ? '有效' : '无效'})`"
                :value="account.platform_user_id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="视频标题（必填）：" prop="videoTitle">
          <el-radio-group v-model="workInfoForm.videoTitleInputType">
            <el-radio value="tag">素材库</el-radio>
            <el-radio value="input">输入</el-radio>
          </el-radio-group>
          <el-input v-if="workInfoForm.videoTitleInputType ==='input'" :rows="2"
                    type="textarea"
                    placeholder="必填，请输入视频标题"
                    v-model="workInfoForm.videoTitle"/>
          <div v-else style="width: 100%">
            <div style="display: flex; flex-direction: row; align-items: center; ">
              <div>
                <span style="font-weight: bold">素材标签：</span>
                <el-select multiple v-model="workInfoForm.videoTitle" style="width: 300px" placeholder="请选择素材标签"
                           clearable>
                  <el-option
                      v-for="tag in allTags"
                      :disabled="tag.text_count <= 0"
                      :key="tag.code"
                      :label='tag.name + "  (文本个数:" + tag.text_count + ")"'
                      :value="tag.id"
                  />
                </el-select>
              </div>
              <div>
                <span style="font-weight: bold; margin-left: 10px;">素材使用策略：</span>
                <el-select v-model="workInfoForm.videoTitleReleaseType" style="width: 200px"
                           placeholder="请选择素材使用策略" clearable>
                  <el-option label="不可重复使用" value="once"></el-option>
                  <el-option label="最近最少使用" value="last_least_use"></el-option>
                </el-select>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="视频正文（选填）：" prop="videoDesc">
          <el-radio-group v-model="workInfoForm.videoDescInputType">
            <el-radio value="tag">素材库</el-radio>
            <el-radio value="input">输入</el-radio>
          </el-radio-group>
          <el-input v-if="workInfoForm.videoDescInputType ==='input'" :rows="2"
                    type="textarea"
                    placeholder="可填，请输入视频正文"
                    v-model="workInfoForm.videoDesc"/>
          <div v-else style="width: 100%">
            <div style="display: flex; flex-direction: row; align-items: center;  ">
              <div>
                <span style="font-weight: bold">素材标签：</span>
                <el-select multiple v-model="workInfoForm.videoDesc" style="width: 300px" placeholder="请选择素材标签"
                           clearable>
                  <el-option
                      v-for="tag in allTags"
                      :disabled="tag.text_count <= 0"
                      :key="tag.code"
                      :label='tag.name + "  (文本个数:" + tag.text_count + ")"'
                      :value="tag.id"
                  />
                </el-select>
              </div>
              <div>
                <span style="font-weight: bold; margin-left: 10px;">素材使用策略：</span>
                <el-select v-model="workInfoForm.videoDescReleaseType" style="width: 200px"
                           placeholder="请选择素材使用策略" clearable>
                  <el-option label="不可重复使用" value="once"></el-option>
                  <el-option label="最近最少使用" value="last_least_use"></el-option>
                </el-select>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="视频话题（选填）：" prop="videoTags">
          <el-radio-group v-model="workInfoForm.videoTagsInputType">
            <el-radio value="tag">素材库</el-radio>
            <el-radio value="input">输入</el-radio>
          </el-radio-group>
          <el-input v-if="workInfoForm.videoTagsInputType ==='input'" :rows="2"
                    type="textarea"
                    placeholder="可填，格式示例：#可爱#猫咪#萌宠"
                    v-model="workInfoForm.videoTags"/>
          <div v-else style="width: 100%">
            <div style="display: flex; flex-direction: row; align-items: center;  ">
              <div>
                <span style="font-weight: bold">素材标签：</span>
                <el-select multiple v-model="workInfoForm.videoTags" style="width: 300px" placeholder="请选择素材标签"
                           clearable>
                  <el-option
                      v-for="tag in allTags"
                      :disabled="tag.text_count <= 0"
                      :key="tag.code"
                      :label='tag.name + "  (文本个数:" + tag.text_count + ")"'
                      :value="tag.id"
                  />
                </el-select>
              </div>
              <div>
                <span style="font-weight: bold; margin-left: 10px;">素材使用策略：</span>
                <el-select v-model="workInfoForm.videoTagsReleaseType" style="width: 200px"
                           placeholder="请选择素材使用策略" clearable>
                  <el-option label="不可重复使用" value="once"></el-option>
                  <el-option label="最近最少使用" value="last_least_use"></el-option>
                </el-select>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="作品视频（必填）：" prop="ossUrl">
          <el-radio-group v-model="workInfoForm.videoUrlInputType">
            <el-radio value="tag">素材库</el-radio>
            <el-radio value="input">上传</el-radio>
          </el-radio-group>
          <div v-if="workInfoForm.videoUrlInputType ==='input'" style="width: 100%">
            <el-upload
                style="min-width: 300px"
                ref="upload"
                :action="uploadVideoUrl"
                :on-success="handleUploadSuccess"
                :file-list="fileList"
                :limit="1"
                :on-exceed="handleExceed"
                :before-upload="beforeUpload"
                list-type="text"
                accept="video/*"
                :on-preview="handlePictureCardPreview"
                :http-request="uploadToOss">
              <el-button type="primary" link :loading="uploadFileLoading">
                <el-icon>
                  <CirclePlusFilled/>
                </el-icon>
                点击上传
              </el-button>
            </el-upload>
          </div>
          <div v-else style="width: 100%">
            <div style="display: flex; flex-direction: row; align-items: center;  ">
              <div>
                <span style="font-weight: bold">素材标签：</span>
                <el-select multiple v-model="workInfoForm.ossUrl" style="width: 300px" placeholder="请选择素材标签"
                           clearable>
                  <el-option
                      v-for="tag in allTags"
                      :disabled="tag.video_count <= 0"
                      :key="tag.code"
                      :label='tag.name + "  (视频个数:" + tag.video_count + ")"'
                      :value="tag.id"
                  />
                </el-select>
              </div>
              <div>
                <span style="font-weight: bold; margin-left: 10px;">素材使用策略：</span>
                <el-select v-model="workInfoForm.videoUrlReleaseType" style="width: 200px"
                           placeholder="请选择素材使用策略" clearable>
                  <el-option label="不可重复使用" value="once"></el-option>
                  <el-option label="最近最少使用" value="last_least_use"></el-option>
                </el-select>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="发布类型：" prop="publishTimeType">
          <el-radio-group v-model="workInfoForm.publishTimeType">
            <el-radio value="now">立刻发布</el-radio>
            <el-radio value="time">定时发布</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="workInfoForm.publishTimeType === 'time'" label="发布日期：">
          <el-date-picker
              v-model="workInfoForm.publishDate"
              type="daterange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :disabled-date="disabledDate"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
          />
        </el-form-item>
        <el-form-item v-if="workInfoForm.publishTimeType === 'time'" label="发布时间：">
          <div>
            <div v-for="(item, index) in workInfoForm.publishTime" :key="index"
                 style="display: flex; align-items: center; margin-right: 20px; margin-bottom: 10px;">
              <!-- 时间选择框 -->
              <el-time-picker
                  v-model="workInfoForm.publishTime[index]"
                  placeholder="选择时间"
                  arrow-control
                  format="HH:mm"
                  value-format="HH:mm"
                  style="width: 200px;"
              />
              <!-- 删除图标 -->
              <el-icon style="margin-left: 10px; cursor: pointer;" @click="removePublishTime(index)">
                <Delete/>
              </el-icon>
            </div>
            <el-button @click="addPublishTime()" style="margin-top: 10px;">添加时间</el-button>
          </div>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
              :rows="2"
              type="textarea"
              placeholder="可选，备注"
              v-model="workInfoForm.remark"/>
        </el-form-item>
        <el-form-item v-if="showVerifyCode" label="验证码：" prop="verifyCode>">
          <el-input
              placeholder="请输入4位验证码"
              style="width: 240px; margin-right: 20px"
              v-model="workInfoForm.verifyCode">
          </el-input>
          <el-button type="primary" @click="syncVerifyCode" :loading="syncCodeLoading">同步验证码</el-button>
        </el-form-item>
        <el-form-item label="发布结果：" prop="message>">
          <el-text type="danger">{{ workInfoForm.message }}</el-text>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="info" @click="handleClickCancel">取消</el-button>
          <el-button type="primary" @click="submitForm('workInfoForm')" :loading="submitLoading">提交</el-button>
        </div>
      </template>
    </el-dialog>
    <!--点击查看-->
    <el-dialog title="查看作品" v-model="previewVideoDialogVisible" destroy-on-close :width="'60%'">
      <template v-if="isVideo(filePathOrUrl)">
        <div style="width: 100%">
          <video width="100%" style="max-height: 600px;" controls>
            <source :src="filePathOrUrl" type="video/mp4">
            您的浏览器不支持视频播放。
          </video>
        </div>
      </template>
      <template v-else>
        <img :src="filePathOrUrl" alt="图片预览" style="width: 100%; max-height: 100%;">
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axiosInstance from '@/api/axiosInstance';
import {Check, CirclePlusFilled, Delete, Download, Search} from '@element-plus/icons-vue';
import Pagination from "@/components/pagination/index.vue";
import axios from 'axios';
import VideoPlayer from "@/components/VideoPlayer.vue";

export default {
  name: "douyin-work-info",
  components: {
    Check,
    VideoPlayer,
    Pagination,
    CirclePlusFilled, Search, Download, Delete
  },
  computed: {
    isSuperAdmin() {
      return this.$store.state.isSuperAdmin;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  data() {
    return {
      removePublishTime(index) {
        // 删除执行下表为index的发布时间
        this.workInfoForm.publishTime.splice(index, 1);
      },
      addPublishTime() {
        // 新增一行空的时间选择
        this.workInfoForm.publishTime.push('');
      },
      disabledDate: function (time) {
        const start = new Date();
        start.setDate(start.getDate() - 1);
        const end = new Date();
        end.setDate(end.getDate() + 14);
        return time < start || time > end;
      },
      selectedWorkIds: [],
      allTags: [],
      tagQueryParams: {
        pageNum: 1,
        pageSize: 100,
      },
      tenantInfoList: [],
      queryParams: {
        tenantId: "",
        pageNum: 1,
        pageSize: 10,
        platformUserId: "",
        platformUserNickname: "",
        workStatus: "",
        platformWorkStatus: "",
        remark: "",
      },
      accountInfoList: [],
      reportTableData: [],
      openPreview: true,
      loading: false,
      total: 0,
      loginRecord: {},
      workInfoList: [],
      showPublishVideoDialog: false,
      fileList: [],
      uploadVideoUrl: "",
      loginId: '',
      activeStep: 0,
      verifyCode: '',
      qrCodeRemainingTime: 0,
      autoWorkTimer: null,
      submitLoading: false,
      syncCodeLoading: false,
      showVerifyCode: false,
      uploadFileLoading: false,
      allSelectedOption: {
        id: 'all',
        label: '全选有效账号',
        value: 'all'
      },
      workInfoForm: {
        id: null,
        tenantId: '',
        platformUserIds: [],
        // 视频标题
        videoTitleInputType: "tag",
        videoTitle: '',
        videoTitleReleaseType: 'once',
        // 视频正文
        videoDescInputType: "tag",
        videoDesc: '',
        videoDescReleaseType: 'once',
        // 视频话题
        videoTagsInputType: "tag",
        videoTags: '',
        videoTagsReleaseType: 'once',
        // 视频作品
        videoUrlInputType: "tag",
        ossUrl: '',
        videoUrlReleaseType: 'once',
        // 发布类型
        publishTimeType: "now",
        // 发布日期
        publishDate: [],
        // 发布时间
        publishTime: [''],
        // 备注
        remark: '',
        message: '',
        verifyCode: '',
      },
      rules: {
        //platformUserId: [{required: true, message: '抖音用户是必填项', trigger: 'blur'}],
        // videoTitle: [{required: true, message: '视频标题是必填项', trigger: 'blur'}],
        // videoDesc: [{required: true, message: '视频正文是必填项', trigger: 'blur'}],
        // videoTags: [{required: true, message: '视频话题是必填项', trigger: 'blur'}],
        // file_url: [{required: true, message: '请上传一张图片', trigger: 'change'}]
      },
      previewVideoDialogVisible: false,
      dialogImageUrl: "",
      filePathOrUrl: '',
      recommendedImages: [],
      selectedImageUrl: "",
      hoveredImageUrl: "",
      loginRecordTimer: null,
    };
  },
  created() {
    if (this.$route.query.platformUserId) {
      this.queryParams.platformUserId = this.$route.query.platformUserId
    }
    if (this.$route.query.publishVideo) {
      this.showPublishVideoDialog = true;
      this.workInfoForm.platformUserId = this.$route.query.platformUserId;
    }
    this.fetchDouyinWorkInfo();
  },
  mounted() {
    this.fetchDouyinAccountInfo();
    this.fetchTagInfos();
    if (this.isSuperAdmin) {
      axiosInstance.get('/api/tenant')
          .then(response => {
            if (response.data && response.data.code === 0) {
              this.tenantInfoList = response.data.data;
            } else {
              this.$message.error(response.data.msg);
            }
          }).catch(error => {
        console.error(error);
      });
    }
  },
  methods: {
    gotoDouyinWorkUrl(platformWorkId) {
      const url = `https://www.douyin.com/video/${platformWorkId}`;
      window.open(url, '_blank');
    },
    handleTableSelectionChange(rows) {
      console.log("handleTableSelectionChange", rows)
      // rows 对象数组 取id字段 转对应数组
      this.selectedWorkIds = rows.map(item => item.id) || [];
    },
    handleSelectDyUserChange(selectedValues) {
      // 如果全选被选中，选择所有有效的用户
      if (selectedValues.includes(this.allSelectedOption.value)) {
        this.workInfoForm.platformUserIds = this.accountInfoList.filter(account => account.status === 1).map(account => account.platform_user_id);
      }
    },
    // 查询tag
    fetchTagInfos() {
      if (!this.isSuperAdmin) {
        this.tagQueryParams.tenantId = this.tenantId
      }
      axiosInstance.post('/api/tag/list', this.tagQueryParams)
          .then(response => {
            if (response.data && response.data.code === 0) {
              this.allTags = response.data.data.results;
              this.allTags.forEach(tag => {
                tag.checked = false;
              })
              // console.log("this.allTags", this.allTags)
            } else {
              this.$message.error(response.data.msg);
            }
          }).catch(error => {
        console.error(error)
      });
    },
    fetchDouyinWorkInfo() {
      this.loading = true
      if (!this.isSuperAdmin) {
        this.queryParams.tenantId = this.tenantId
      }
      if (this.queryParams.pageSize > 20) {
        this.openPreview = false;
      }
      axiosInstance.post('/api/douyin/work/list', this.queryParams)
          .then(response => {
            if (response.data && response.data.code === 0) {
              this.workInfoList = response.data.data.results;
              this.total = response.data.data.total;
            } else {
              this.$message.error(response.data.msg);
            }
            this.loading = false
          }).catch(error => {
        this.loading = false
        console.error(error)
      });
    },
    publishDouyinVideo() {
      this.showPublishVideoDialog = true;
      this.setWorkInfoFormBeforeOpen()
    },
    setWorkInfoFormBeforeOpen(data) {
      if (!data) {
        this.workInfoForm = {
          id: null,
          tenantId: '',
          platformUserIds: [],
          // 视频标题
          videoTitleInputType: "tag",
          videoTitle: '',
          videoTitleReleaseType: 'once',
          // 视频正文
          videoDescInputType: "tag",
          videoDesc: '',
          videoDescReleaseType: 'once',
          // 视频话题
          videoTagsInputType: "tag",
          videoTags: '',
          videoTagsReleaseType: 'once',
          // 视频作品
          videoUrlInputType: "tag",
          ossUrl: '',
          videoUrlReleaseType: 'once',
          // 发布类型
          publishTimeType: "now",
          // 发布日期
          publishDate: [],
          // 发布时间
          publishTime: [''],
          // 备注
          remark: '',
          message: '',
          verifyCode: '',
        }
      }
    },
    clickRePublish(row) {
      if (!row || this.selectedWorkIds.length === 0) {
        this.$message.error('请选择要重新发布的作品');
        return
      }
      let ids = this.selectedWorkIds || [row.id];
      console.log("ids", ids)
      let that = this;
      this.$messageBox.confirm(
          '重新发布作品时，会直接复用已有的素材信息，确认要重新发布作品吗？',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
      ).then(() => {
        axiosInstance.post('/api/douyin/re_publish_video', {
          ids: ids
        }).then(response => {
          if (response.data.code === 0) {
            that.$message.success("重新发布中，请稍后查看结果");
          } else {
            that.$message.error("重新发布失败，请稍后重试");
          }
        }).catch(error => {
          console.error(error)
          that.$message.error("重新发布失败，请稍后重试");
        });
      }).catch(() => {
        // 用户点击取消后的逻辑
        this.$message.info('取消重新发布');
      });
    },
    clickSeeWorkData(row) {
      let platformWorkId = row.platform_work_id;
      let platformUserId = row.platform_user_id;
      this.$router.push({
        path: `/douyin/work-data`,
        query: {platformWorkId: platformWorkId, platformUserId: platformUserId}
      });
    },
    getTagType(status) {
      switch (status) {
        case 'publishing':
          return 'primary';
        case 'publish_success':
          return 'success';
        case 'publish_failed':
          return 'danger';
        case 'auditing':
          return 'info';
        case 'deleted':
          return 'danger';
        default:
          return 'info';
      }
    },
    getStatusText(status) {
      switch (status) {
        case 'publishing':
          return '发布中';
        case 'publish_success':
          return '发布成功';
        case 'publish_failed':
          return '发布失败';
        case 'auditing':
          return '审核中';
        case 'deleted':
          return '已删除';
        case 'wait_verify_code':
          return '等待验证码'
        default:
          return '未知状态';
      }
    },
    getPlatformWorkTagType(status) {
      switch (status) {
        case 'publish_auditing':
          return 'info';
        case 'publish_success':
          return 'success';
        case 'publish_failed':
          return 'danger';
        case 'publish_timing':
          return 'warning';
        default:
          return '';
      }
    },
    getPlatformWorkStatusText(status) {
      switch (status) {
        case 'publish_timing':
          return '定时发布中';
        case 'publish_success':
          return '已发布';
        case 'publish_failed':
          return '未通过';
        case 'publish_auditing':
          return '审核中';
        default:
          return '--';
      }
    },
    syncVerifyCode() {
      this.syncCodeLoading = true
      axiosInstance.post('/api/douyin/sync_verify_code', {
        loginId: this.loginId,
        verifyCode: this.verifyCode
      }).then(response => {
        if (response.data.code === 0) {
          this.$message.success("验证码同步成功");
        } else {
          this.$message.error("验证码同步失败");
        }
        this.syncCodeLoading = false
      }).catch(error => {
        this.syncCodeLoading = false
        console.error(error)
      });
    },
    editRemark(row) {
      const payload = {
        id: row.id,  // Assuming each row has a unique identifier
        remark: row.remark
      };
      axiosInstance.post('/api/douyin/work/edit_remark', payload)
          .then(response => {
            if (response.data && response.data.code === 0) {
              this.$message.success('备注更新成功');
            } else {
              this.$message.error(response.data.msg);
            }
          })
          .catch(error => {
            console.error(error);
            this.$message.error('备注更新失败');
          });
    },
    isVideo(url) {
      try {
        const parsedUrl = new URL(url);
        const videoExtensions = ['.mp4', '.avi', '.mov', '.mkv', '.flv', '.wmv'];
        return videoExtensions.some(ext => parsedUrl.pathname.endsWith(ext));
      } catch (e) {
        console.error('Invalid URL:', url, e);
        return false;
      }
    },
    uploadToOss({file}) {
      axios.put(this.uploadVideoUrl, file, {
        headers: {
          'Content-Type': 'video/*',
        }
      }).then(response => {
        console.info("response", response)
        this.handleUploadSuccess(response.data, file, this.fileList);
      }).catch(error => {
        this.fileList = []
        this.$message.error('上传失败');
        console.error(error);
      });
    },
    handleUploadSuccess(response, file, fileList) {
      this.workInfoForm.ossUrl = this.uploadVideoUrl
      this.fileList = fileList;
      this.uploadFileLoading = false;
      this.$message.success('上传成功');
    },
    handleExceed(files, fileList) {
      this.$message.warning('只能上传一张图片');
    },
    async beforeUpload(file) {
      const isImage = file.type.startsWith('video/');
      if (!isImage) {
        this.$message.error('只能上传视频文件');
        return false
      }
      try {
        const response = await axiosInstance.post(`/api/presigned_url_for_oss`, {
          filename: file.name,
          method: 'put',
          platformUserId: this.workInfoForm.platformUserId,
          platformType: 'douyin',
        })
        console.log(response)
        if (response.data.code === 0) {
          this.uploadVideoUrl = response.data.data.oss_url;
          this.uploadFileLoading = true;
          return true;
        } else {
          this.$message.error('获取上传URL失败');
          return false;
        }
      } catch (error) {
        console.error(error);
        this.$message.error('获取上传URL失败');
        return false;
      }
    },
    handlePictureCardPreview(file) {
      this.filePathOrUrl = file.url;
      this.previewVideoDialogVisible = true;
    },
    handleClickCancel() {
      this.showPublishVideoDialog = false;
      this.submitLoading = false;
    },
    submitForm(formName) {
      let that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 提交表单逻辑
          this.workInfoForm.tenantId = this.tenantId;
          console.log('表单验证通过，可以提交数据', this.workInfoForm);
          this.submitLoading = true;
          // 用户点击了确定按钮
          axiosInstance.post(`/api/douyin/publish_video`, this.workInfoForm).then(response => {
            that.submitLoading = false;
            if (response.data && response.data.code === 0) {
              that.$message({
                type: 'success',
                message: '作品发布成功!'
              });
              that.showPublishVideoDialog = false;
              // 刷新作平信息列表
              that.fetchDouyinWorkInfo();
            } else {
              that.$message({
                type: 'error',
                message: '作品发布失败: ' + response.data.msg
              });
              that.workInfoForm.message = response.data.msg
            }
          }).catch(error => {
            this.submitLoading = false;
            that.showPublishVideoDialog = false;
            that.$message({
              type: 'error',
              message: '作品发布失败: ' + error.message
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    fetchDouyinAccountInfo() {
      let queryParamsNew = {...this.queryParams};
      queryParamsNew.pageSize = 1000
      if (!this.isSuperAdmin) {
        queryParamsNew.tenantId = this.tenantId;
      }
      axiosInstance.post('/api/douyin/account/list', queryParamsNew)
          .then(response => {
            if (response.data && response.data.code === 0) {
              this.accountInfoList = response.data.data.results;
            } else {
              this.$message.error(response.data.msg);
            }
          }).catch(error => {
        console.error(error)
      });
    },
    stopAllTimer() {
      console.log('stopAllTimer')
      if (this.autoWorkTimer) {
        clearInterval(this.autoWorkTimer);
      }
    },
  },
  beforeUnmount() {
    this.stopAllTimer();
  }
};
</script>

<style scoped>
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 20px;
}

.main-header-h1 {
  text-align: center;
  color: #333;
}

#button-container {
  text-align: center;
  margin-bottom: 20px;
}

.add-user-button {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-user-button:hover {
  background-color: #0056b3;
}

#cookie-info-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.cookie-info {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.cookie-info h2 {
  margin: 0 0 10px;
  font-size: 1.5em;
  font-weight: 600;
  color: #333;
}

.cookie-info span {
  margin-bottom: 5px;
  font-size: 1em;
  color: #555;
}

.cookie-status-valid {
  color: green;
}

.cookie-status-invalid {
  color: red;
}

.step-content {
  text-align: center;
  margin-top: 20px;
}

.highlight {
  color: red;
  font-weight: bold;
}

.photo-wall {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-height: 200px;
  overflow: auto;
}

.photo-item {
  width: calc(33.33% - 10px);
  cursor: pointer;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.photo-item img {
  width: 100%;
  height: auto;
}

.photo-item.selected {
  border: 2px solid #409eff;
}

.actions {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  background: rgba(0, 0, 0, 0.5);
  color: #d9ecff;
  padding: 5px 0;
}

.actions span {
  cursor: pointer;
}
</style>