
<template>
  <svg t="1718605602357" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
       p-id="6080" width="200" height="200">
    <path
        d="M618.666667 149.333333h-85.333334v512a128 128 0 1 1-85.333333-120.533333V452.266667a217.6 217.6 0 0 0-42.666667-4.266667 213.333333 213.333333 0 1 0 213.333334 213.333333V358.186667A298.666667 298.666667 0 0 0 832 448v-85.333333A213.333333 213.333333 0 0 1 618.666667 149.333333z"
        p-id="6081" fill="#FFFFFF"></path>
  </svg>
</template>

<script>
export default {
  name: 'DouyinIcon',
};
</script>

<style scoped>
</style>