import {createStore} from 'vuex';

const store = createStore({
    state: {
        coin: 0,
        isLogin: false,
        isSuperAdmin: false,
        tenantId: null,
        userInfo: null,
        accessToken: null,
        routePaths: null,
    },
    mutations: {
        setCoin(state, coin) {
            state.coin = coin;
        },
        setIsLogin(state, isLogin) {
            state.isLogin = isLogin;
        },
        setTenantId(state, tenantId) {
            state.tenantId = tenantId;
        },
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo;
        },
        setAccessToken(state, accessToken) {
            state.accessToken = accessToken;
        },
        setIsSuperAdmin(state, isSuperAdmin) {
            state.isSuperAdmin = isSuperAdmin;
        },
        setRoutePaths(state, routePaths) {
            state.routePaths = routePaths;
        },
        clearLoginData(state) {
            state.isLogin = false;
            state.tenantId = null;
            state.userInfo = null;
            state.accessToken = null;
            state.isSuperAdmin = false;
            state.routePaths = null;
            state.coin = 0;
        },
    },
    actions: {
        login({commit}, loginData) {
            commit('setCoin', loginData.userInfo.coin)
            commit('setRoutePaths', loginData.routePaths)
            commit('setTenantId', loginData.userInfo.tenantId);
            commit('setUserInfo', loginData.userInfo);
            commit('setAccessToken', loginData.accessToken);
            commit('setIsSuperAdmin', loginData.isSuperAdmin);
            commit('setIsLogin', true);
            localStorage.setItem('token', loginData.accessToken)
        },
        logout({commit}) {
            localStorage.clear();
            commit('clearLoginData');
        },
        resetCoin({commit}, coin) {
            commit('setCoin', coin);
            if (localStorage.getItem('loginData')) {
                const loginData = JSON.parse(localStorage.getItem('loginData'));
                loginData.userInfo.coin = coin;
                localStorage.setItem('loginData', JSON.stringify(loginData));
            }
        }
    },
});

export default store;
