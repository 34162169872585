<template>
  <svg t="1729481504996" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
       p-id="10802" :width="width" :height="height">
    <path d="M512 509.1m-444.4 0a444.4 444.4 0 1 0 888.8 0 444.4 444.4 0 1 0-888.8 0Z" fill="#FAB001"
          p-id="10803"></path>
    <path
        d="M887.7 459.1c0-132.5-58-251.3-150-332.7C671.6 87.2 594.4 64.7 512 64.7c-245.4 0-444.4 199-444.4 444.4 0 132.5 58 251.3 150 332.7 66.2 39.1 143.3 61.7 225.8 61.7 245.4 0 444.3-199 444.3-444.4z"
        fill="#F9E48A" p-id="10804"></path>
    <path
        d="M568 102.5c-245.4 0-444.4 199-444.4 444.4 0 122.5 49.7 233.4 130 313.8 57.6 27.3 121.8 42.8 189.8 42.8 245.4 0 444.4-199 444.4-444.4 0-122.5-49.7-233.4-130-313.8-57.6-27.3-121.9-42.8-189.8-42.8z"
        fill="#FECE0A" p-id="10805"></path>
    <path
        d="M704.4 616.4c0 28.6-4.9 44.6-25.2 54.3-15.4 7.3-35 9.4-60.2 10-11.8 0.3-22.1-7.7-25.4-19-0.4-1.5-0.9-3.1-1.4-4.6-5.3-16.7 7.3-33.6 24.8-33.6 8 0 14.3-0.1 17.1-0.3 7.8 0 9.7-1.9 9.7-8.2V514.3c0-14.3-11.6-25.9-25.9-25.9h-36.4c-14.3 0-25.9 11.6-25.9 25.9v195.5c0 14.3-11.6 25.9-25.9 25.9H521c-14.3 0-25.9-11.6-25.9-25.9V514.3c0-14.3-11.6-25.9-25.9-25.9h-33c-14.3 0-25.9 11.6-25.9 25.9v141.6c0 14.3-11.6 25.9-25.9 25.9h-7.3c-14.3 0-25.9-11.6-25.9-25.9V458c0-14.3 11.6-25.9 25.9-25.9h92.2c14.3 0 25.9-11.6 25.9-25.9v-10.9c0-14.8-12.4-26.6-27.2-25.9-38.8 1.7-77.5 2.7-114.6 3-12.1 0.1-22.9-8.3-25.4-20.1 0-0.2-0.1-0.4-0.1-0.6-3.4-16.1 8.3-31.4 24.8-31.8 118-2.7 245.9-10.6 338.3-22.8 11.6-1.5 22.7 4.9 27.2 15.7l1.1 2.6c6.6 15.8-3.4 33.6-20.4 35.8-36.9 4.9-77.2 8.7-119.3 11.9-13.5 1-23.9 12.3-23.9 25.9v17.3c0 14.3 11.6 25.9 25.9 25.9h97c14.3 0 25.9 11.6 25.9 25.9v158.3z"
        fill="#FFFFFF" p-id="10806"></path>
  </svg>
</template>

<script>
export default {
  name: "TryOnResultIcon",
  props: {
    width: {
      type: Number,
      default: 24
    },
    height: {
      type: Number,
      default: 24
    },
  }
};
</script>

<style scoped>
</style>
