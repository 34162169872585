<template>
  <div :class="{ 'hidden': hidden }" class="pagination-container">
    <el-pagination
      :background="background"
      v-model="currentPage"
      v-model:page-size="pageSize"
      :layout="layout"
      :page-sizes="pageSizes"
      :pager-count="pagerCount"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import { scrollTo } from '@/utils/scroll-to'
export default {
  name: 'Pagination',
  props: {
    total: Number,
    page: Number,
    limit: Number,
    pageSizes: {
      type: Array,
      default: () => [10, 20, 50, 100, 200, 500]
    },
    pagerCount: {
      type: Number,
      default: () => (document.body.clientWidth < 992 ? 5 : 7)
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    background: {
      type: Boolean,
      default: true
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    },
    float: {
      type: String,
      default: 'right'
    }
  },
  data() {
    return {
      currentPage: this.page,
      pageSize: this.limit
    }
  },
  watch: {
    page(newVal) {
      this.currentPage = newVal;
    },
    limit(newVal) {
      this.pageSize = newVal;
    }
  },
  methods: {
    handleSizeChange(val) {
      if (this.currentPage * val > this.total) {
        this.currentPage = 1;
      }
      this.$emit('update:page', this.currentPage);
      this.$emit('update:limit', val);
      this.$emit('pagination', { page: this.currentPage, limit: val });
      if (this.autoScroll) {
        scrollTo(0, 800);
      }
    },
    handleCurrentChange(val) {
      this.$emit('update:page', val);
      this.$emit('pagination', { page: val, limit: this.pageSize });
      if (this.autoScroll) {
        scrollTo(0, 800);
      }
    }
  }
}
</script>

<style scoped>
.pagination-container {
  padding: 32px 16px;
  .el-pagination {
    float: v-bind(float);
  }
}
.pagination-container.hidden {
  display: none;
}
</style>