<template>
  <div>
    <!--    <h1 class="main-header-h1">抖音账号信息展示</h1>-->
    <!--搜索区域-->
    <el-form ref="queryFormRef" :model="queryParams" :inline="true" label-width="100px">
      <el-form-item v-if="isSuperAdmin" label="租户：" prop="tenantId">
        <el-select v-model="queryParams.tenantId" style="width: 300px" placeholder="请选择租户" clearable>
          <el-option
              v-for="tenant in tenantInfoList"
              :key="tenant.tenantId"
              :label="`${tenant.tenantName} (${tenant.status == 1 ? '有效' : '无效'})`"
              :value="tenant.tenantId"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="抖音昵称：" prop="platformUserNickname">
        <el-input v-model="queryParams.platformUserNickname" placeholder="请输入抖音昵称" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="fetchCookieInfo">
          <el-icon>
            <Search/>
          </el-icon>
          <span>搜索</span>
        </el-button>
        <el-button type="success" @click="addDouyinUser">
          <el-icon>
            <CirclePlusFilled/>
          </el-icon>
          <span>添加抖音账号</span>
        </el-button>
        <el-button :loading="checkAccountLoading" type="primary"
                   :disabled="selectedPlatformUserIds === null || selectedPlatformUserIds.length === 0"
                   @click="checkAccountCookie">
          <el-icon>
            <Check/>
          </el-icon>
          <span>一键校验</span>
        </el-button>
      </el-form-item>
    </el-form>
    <!--表格数据-->
    <el-table :data="cookieInfoList" v-loading="loading" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"/>
      <!--      <el-table-column prop="id" label="ID" width="100"></el-table-column>-->
      <el-table-column prop="platform_user_avatar" width="100" label="头像">
        <template #default="scope">
          <el-avatar :src="scope.row.platform_user_avatar"></el-avatar>
        </template>
      </el-table-column>
      <el-table-column prop="platform_user_nickname" label="抖音昵称"></el-table-column>
      <el-table-column prop="platform_user_id" label="抖音ID"/>
      <!--      <el-table-column prop="login_id" label="登录ID"/>-->
      <!--      <el-table-column prop="expire_time" label="预计到期时间"/>-->
      <el-table-column prop="status" label="账号发布授权">
        <template #default="scope">
          <el-tag v-if="scope.row.status === 1" type="success">
            授权有效
          </el-tag>
          <el-button v-else style="margin-top: 10px" size="small" type="warning"
                     @click="addDouyinUser">重新授权
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="auth_status" label="账号数据授权">
        <template #default="scope">
          <el-tag v-if="scope.row.auth_status === 1" type="success">
            授权有效
          </el-tag>
          <el-button v-else style="margin-top: 10px" size="small" type="warning"
                     @click="authDouyinOpenApi(scope.row)">重新授权
          </el-button>
        </template>
      </el-table-column>
      <!--      <el-table-column prop="tags" label="标签" width="200">-->
      <!--        <template #default="scope">-->
      <!--          <el-input v-model="scope.row.tags" @change="editTags(scope.row)"-->
      <!--                    placeholder="请输入账号标签"></el-input>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column prop="remark" label="备注" width="200">
        <template #default="scope">
          <el-input :rows="2"
                    type="textarea" v-model="scope.row.remark" @change="editRemark(scope.row)"
                    placeholder="请输入备注"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="login_time" label="最近登录时间"/>
      <el-table-column fixed="right" label="操作" width="160">
        <template #default="scope">
          <div style="display: flex; flex-direction: column; align-items: flex-start;">
            <el-button link disabled></el-button>
            <el-button style="margin-bottom: 5px;" link type="info" @click.prevent="clickSeeDouyinWork(scope.row)">
              查看作品
            </el-button>
            <el-button v-if="isSuperAdmin" style="margin-bottom: 5px;" link type="success"
                       :loading="openConsoleLoading"
                       @click.prevent="clickOpenDouyinConsole(scope.row)">
              打开控制台
            </el-button>
            <el-button style="margin-bottom: 5px;" link type="danger" @click.prevent="deleteAccount(scope.row)">
              删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--分页数据-->
    <pagination :total="total" v-model:page="queryParams.pageNum"
                v-model:limit="queryParams.pageSize" @pagination="fetchCookieInfo"/>

    <!--添加抖音账号Dialog-->
    <el-dialog :before-close="handleDialogClose"
               title="添加抖音用户"
               v-model="showAddUserDialog"
               destroy-on-close
               width="50%"
               style="min-height: 300px"
               :close-on-click-modal="false">
      <el-steps style="max-width: 600px; margin: 0 auto;"
                :space="200"
                :active="activeStep"
                finish-status="success">
        <el-step title="二维码生成中"></el-step>
        <el-step title="扫码登录"></el-step>
        <el-step title="输入验证码"></el-step>
        <el-step title="查询用户信息"></el-step>
        <el-step title="完成"></el-step>
        <el-step title="出错"></el-step>
      </el-steps>
      <div class="step-content" v-if="loginStatus === 'qr_code_generating' || loginStatus === 'login_init'">
        <p style="color: #409EFF; font-weight: bold;">正在生成二维码中，请稍等...</p>
      </div>
      <div class="step-content" v-if="loginStatus === 'wait_scan_qr_code'">
        <div class="flex justify-center">
          <img :src="loginRecord.qr_code" alt="QR Code" width="200" height="200"/>
        </div>
        <p v-if="qrCodeRemainingTime > 0">
          <span>请用手机打开抖音，并扫二维码，二维码到期后请关闭页面重试</span>
          <br>
          <span>二维码剩余时间: <span class="highlight">{{ qrCodeRemainingTime }}</span> 秒</span>
        </p>
        <p v-else>
          <span class="highlight">二维码已到期</span>
        </p>
      </div>
      <div class="step-content" v-if="loginStatus === 'verify_code_generating' || loginStatus === 'wait_verify_code'">
        <el-input v-model="verifyCode" placeholder="请输入6位验证码"></el-input>
        <el-button type="primary" style="margin-top: 20px" :loading="syncCodeLoading" @click="syncVerifyCode">
          同步验证码
        </el-button>
      </div>
      <div class="step-content" v-if="loginStatus === 'fetching_user_info'">
        <p style="color: #409EFF; font-weight: bold;">正在获取用户信息中，请稍等...</p>
      </div>
      <div class="step-content" v-if="loginStatus === 'login_success'">
        <span class="highlight-success">绑定成功！</span>
        <p>{{ loginRecord.message }}</p>
      </div>
      <div class="step-content" v-if="loginStatus === 'login_failed'">
        <p style="color: red;">{{ loginRecord.message }}</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axiosInstance from '@/api/axiosInstance';
import {Check, CirclePlusFilled, Search} from '@element-plus/icons-vue';
import Pagination from "@/components/pagination/index.vue";

export default {
  name: "douyin-work-account",
  computed: {
    isSuperAdmin() {
      return this.$store.state.isSuperAdmin;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  data() {
    return {
      loading: false,
      openConsoleLoading: false,
      checkAccountLoading: false,
      selectedPlatformUserIds: [],
      tenantInfoList: [],
      queryParams: {
        tenantId: '',
        pageNum: 1,
        pageSize: 20,
        platformUserNickname: "",
      },
      total: 0,
      loginRecord: {},
      cookieInfoList: [],
      showAddUserDialog: false,
      loginId: '',
      activeStep: 0,
      loginStatus: '',
      verifyCode: '',
      qrCodeRemainingTime: 0,
      loginRecordTimer: null,
      qrCodeInterval: null,
      syncCodeLoading: false,
    };
  },
  components: {
    Pagination, Check, Search,
    CirclePlusFilled
  },
  created() {
    this.fetchCookieInfo();
  },
  mounted() {
    if (this.isSuperAdmin) {
      axiosInstance.get('/api/tenant')
          .then(response => {
            if (response.data && response.data.code === 0) {
              this.tenantInfoList = response.data.data;
            } else {
              this.$message.error(response.data.msg);
            }
          }).catch(error => {
        console.error(error);
      });
    }
  },
  methods: {
    // 打开抖音OpenAPI授权弹窗
    authDouyinOpenApi(row) {
      let client_key = 'awzo644aye30wow1';
      let response_type = 'code';
      let scope = "user_info,video.data.bind,item.comment,video.list.bind";
      let redirect_uri = "https://taitan.iaimarket.com/api/douyin/auth";
      let state = row.tenant_id + ',' + row.platform_user_id;
      let url = `https://open.douyin.com/platform/oauth/connect?client_key=${client_key}&response_type=${response_type}&scope=${scope}&redirect_uri=${redirect_uri}&state=${state}`;
      console.log(url)
      window.open(url, '_blank');
    },
    clickSeeDouyinWork(row) {
      const platformUserId = row.platform_user_id;
      this.$router.push({path: `/douyin/work`, query: {platformUserId: platformUserId}});
    },
    clickOpenDouyinConsole(row) {
      const payload = {
        platformUserId: row.platform_user_id,  // Assuming each row has a unique identifier
        activateSeconds: 600,
      };
      this.openConsoleLoading = true;
      axiosInstance.post('/api/douyin/account/console', payload)
          .then(response => {
            this.openConsoleLoading = false;
            if (response.data && response.data.code === 0) {
              this.$message.success('打开控制台成功，请前往发布服务器的浏览器查看！');
            } else {
              this.$message.error(response.data.msg);
            }
            this.fetchCookieInfo();
          })
          .catch(error => {
            this.openConsoleLoading = false;
            console.error(error);
            this.$message.error('打开控制台失败');
            this.fetchCookieInfo();
          });
    },
    clickPublishVideoWork(row) {
      const platformUserId = row.platform_user_id;
      this.$router.push({path: `/douyin/work`, query: {platformUserId: platformUserId, publishVideo: true}});
    },
    editRemark(row) {
      const payload = {
        id: row.id,  // Assuming each row has a unique identifier
        remark: row.remark
      };
      axiosInstance.post('/api/douyin/account/edit_remark', payload)
          .then(response => {
            if (response.data && response.data.code === 0) {
              this.$message.success('备注更新成功');
            } else {
              this.$message.error(response.data.msg);
            }
            this.fetchCookieInfo();
          })
          .catch(error => {
            console.error(error);
            this.$message.error('备注更新失败');
            this.fetchCookieInfo();
          });
    },
    editTags(row) {
      const payload = {
        id: row.id,  // Assuming each row has a unique identifier
        tags: row.tags
      };
      axiosInstance.post('/api/douyin/account/edit_tags', payload)
          .then(response => {
            if (response.data && response.data.code === 0) {
              this.$message.success('标签更新成功');
            } else {
              this.$message.error(response.data.msg);
            }
            this.fetchCookieInfo();
          })
          .catch(error => {
            console.error(error);
            this.$message.error('标签更新失败');
            this.fetchCookieInfo();
          });
    },
    handleSelectionChange(rows) {
      console.log("handleSelectionChange", rows)
      // rows 对象数组 取id字段 转对应数组
      this.selectedPlatformUserIds = rows.map(item => item.platform_user_id) || [];
    },
    fetchCookieInfo() {
      this.loading = true;
      if (!this.isSuperAdmin) {
        this.queryParams.tenantId = this.tenantId
      }
      axiosInstance.post('/api/douyin/account/list', this.queryParams).then(response => {
        if (response.data && response.data.code === 0) {
          this.cookieInfoList = response.data.data.results;
          this.total = response.data.data.total;
        } else {
          this.$message.error(response.data.msg);
        }
        this.loading = false
      }).catch(error => {
        this.loading = false
        console.error(error)
      });
    },
    fetchLoginRecord() {
      axiosInstance.post('/api/douyin/get_login_record', {
        loginId: this.loginId,
        tenantId: this.tenantId,
      }).then(response => {
        if (response.data) {
          this.loginRecord = response.data.data;
          this.loginStatus = this.loginRecord.login_status || 'login_init';
          this.updateActiveStep();
          this.updateQrCodeRemainingTime();
          if (this.loginStatus === 'login_failed') {
            setTimeout(() => {
              this.handleDialogClose();
            }, 4000);
          } else if (this.loginStatus === 'login_success') {
            setTimeout(() => {
              this.handleDialogClose();
            }, 4000);
          }
        } else {
          // this.$message.error(response.data.msg);
        }
      }).catch(error => {
        console.error(error)
      });
    },
    generateLoginId() {
      const now = new Date();
      const yyyyMMddHHmm = now.getFullYear().toString() +
          (now.getMonth() + 1).toString().padStart(2, '0') +
          now.getDate().toString().padStart(2, '0') +
          now.getHours().toString().padStart(2, '0') +
          now.getMinutes().toString().padStart(2, '0');
      const randomString = Math.random().toString(36).substring(2, 6).toUpperCase();
      return yyyyMMddHHmm + randomString;
    },
    deleteAccount(row) {
      let that = this;
      this.$confirm('此操作将删除该用户信息, 是否继续?', '确认删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 用户点击了确定按钮，发送删除请求
        axiosInstance.get(`/api/douyin/account/delete/${row.id}`)
            .then(response => {
              that.$message({
                type: 'success',
                message: '用户信息删除成功!'
              });
              // 删除成功后，刷新用户信息列表
              that.fetchCookieInfo();
            })
            .catch(error => {
              that.$message({
                type: 'error',
                message: '删除用户信息失败: ' + error.message
              });
            });
      }).catch(() => {
        // 用户点击了取消按钮
        that.$message({
          type: 'info',
          message: '已取消删除操作'
        });
      });
    },

    addDouyinUser() {
      this.loginId = this.generateLoginId();
      this.showAddUserDialog = true;
      this.loginStatus = 'login_init';
      // 开始定时任务
      this.startLoginRecordTimer();
      axiosInstance.post('/api/douyin/get_login_info', {
        loginId: this.loginId,
        tenantId: this.tenantId || "",
      }).then(response => {
        if (response.data.code === 0) {
          this.$message.success("已添加新的抖音用户");
        } else {
          // this.$message.error("添加抖音用户失败，请稍后重试");
        }
      }).catch(error => {
        console.error(error)
      });
    },
    checkAccountCookie() {
      let that = this;
      that.checkAccountLoading = true;
      // 用户点击了确定按钮，发送删除请求
      axiosInstance.post('/api/douyin/check_cookie', {
        platformUserIds: this.selectedPlatformUserIds
      }, {timeout: 120000}).then(response => {
        that.checkAccountLoading = false;
        if (response && response.data && response.data.code === 0) {
          that.$message({
            type: 'success',
            message: '用户Cookie校验结束!'
          });
        } else {
          that.$message({
            type: 'error',
            message: '用户Cookie校验失败，请稍后重试'
          });
        }
        // 刷新用户信息列表
        that.fetchCookieInfo();
      }).catch(error => {
        that.checkAccountLoading = false;
        that.$message({
          type: 'error',
          message: '用户Cookie校验失败: ' + error.message
        });
      });
    },

    handleDialogClose() {
      console.info("handleDialogClose")
      this.showAddUserDialog = false;
      this.activeStep = 0;
      this.verifyCode = "";
      this.fetchCookieInfo();
      this.stopLoginRecordTimer();
      this.invalidLoginInfo();
    },

    invalidLoginInfo() {
      axiosInstance.post('/api/douyin/invalid_login_info', {
        loginId: this.loginId,
        tenantId: this.tenantId,
      }).then(response => {
        console.log(response.data);
      }).catch(error => {
        console.error(error)
      });
    },

    syncVerifyCode() {
      this.syncCodeLoading = true
      if (!this.verifyCode || this.verifyCode.length !== 6) {
        this.$message.error("请输入6位验证码");
        return
      }
      axiosInstance.post('/api/douyin/sync_verify_code', {
        loginId: this.loginId,
        verifyCode: this.verifyCode
      }).then(response => {
        if (response.data.code === 0) {
          this.$message.success("验证码同步成功");
        } else {
          this.$message.error("验证码同步失败");
        }
        this.syncCodeLoading = false
      }).catch(error => {
        this.syncCodeLoading = false
        console.error(error)
      });
    },

    startLoginRecordTimer() {
      if (this.loginRecordTimer) {
        clearInterval(this.loginRecordTimer);
      }
      this.loginRecordTimer = setInterval(() => {
        this.fetchLoginRecord();
      }, 2000);
    },

    stopLoginRecordTimer() {
      if (this.loginRecordTimer) {
        clearInterval(this.loginRecordTimer);
      }
      if (this.qrCodeInterval) {
        clearInterval(this.qrCodeInterval);
      }
    },

    stopAllTimer() {
      console.log('stopAllTimer')
      if (this.loginRecordTimer) {
        clearInterval(this.loginRecordTimer);
      }
      if (this.qrCodeInterval) {
        clearInterval(this.qrCodeInterval);
      }
    },

    updateActiveStep() {
      switch (this.loginStatus) {
        case 'qr_code_generating':
        case 'login_init':
          this.activeStep = 0;
          break;
        case 'wait_scan_qr_code':
          this.activeStep = 1;
          break;
        case 'verify_code_generating':
        case 'wait_verify_code':
          this.activeStep = 2;
          break;
        case 'fetching_user_info':
          this.activeStep = 3;
          break;
        case 'login_success':
          this.activeStep = 4;
          break;
        case 'login_failed':
          this.activeStep = 5;
          break;
        default:
          this.activeStep = 0;
      }
    },

    updateQrCodeRemainingTime() {
      if (this.loginStatus === 'wait_scan_qr_code') {
        const now = Math.floor(Date.now() / 1000);
        const expireTime = Math.floor(new Date(this.loginRecord.qr_code_expire_time).getTime() / 1000);
        this.qrCodeRemainingTime = expireTime - now;
        if (this.qrCodeInterval) {
          clearInterval(this.qrCodeInterval);
        }
        this.qrCodeInterval = setInterval(() => {
          this.qrCodeRemainingTime--;
          if (this.qrCodeRemainingTime <= 0) {
            clearInterval(this.qrCodeInterval);
          }
        }, 1000);
      }
    }
  },

  beforeUnmount() {
    this.stopAllTimer();
  }
};
</script>

<style scoped>
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 20px;
}

.main-header-h1 {
  text-align: center;
  color: #333;
}

#button-container {
  text-align: center;
  margin-bottom: 20px;
}

.add-user-button {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-user-button:hover {
  background-color: #0056b3;
}

#cookie-info-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.cookie-info {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.cookie-info h2 {
  margin: 0 0 10px;
  font-size: 1.5em;
  font-weight: 600;
  color: #333;
}

.cookie-info span {
  margin-bottom: 5px;
  font-size: 1em;
  color: #555;
}

.cookie-status-valid {
  color: green;
}

.cookie-status-invalid {
  color: red;
}

.step-content {
  text-align: center;
  margin-top: 20px;
}

.highlight {
  color: red;
  font-weight: bold;
}

.highlight-success {
  color: green;
  font-weight: bold;
  font-size: 18px;
}

</style>