<template>
  <div>
    <!--搜索区域-->
    <el-form ref="queryFormRef" :model="queryParams" :inline="true" label-width="100px">
      <el-form-item v-if="isSuperAdmin" label="租户：" prop="tenantId">
        <el-select v-model="queryParams.tenantId" style="width: 200px" placeholder="请选择租户" clearable>
          <el-option
              v-for="tenant in tenantInfoList"
              :key="tenant.tenantId"
              :label="`${tenant.tenantName} (${tenant.status == 1 ? '有效' : '无效'})`"
              :value="tenant.tenantId"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="标签名" prop="name">
        <el-input style="width: 200px" v-model="queryParams.name" placeholder="标签名，支持模糊查询" clearable></el-input>
      </el-form-item>
      <el-form-item label="标签类型" prop="type">
         <el-select style="width: 200px" v-model="queryParams.type" clearable placeholder="请选择标签类型">
            <el-option label="系统标签" value="system">系统标签</el-option>
            <el-option label="自定义标签" value="custom">自定义标签</el-option>
            <el-option label="微信标签" value="wechat">微信标签</el-option>
          </el-select>
      </el-form-item>
      <el-form-item label="标签组名" prop="groupName">
        <el-select style="width: 200px"
              v-model="queryParams.groupName"
              clearable
              placeholder="请选择已有组名">
            <el-option
                v-for="item in tagGroup"
                :key="item"
                :label="item"
                :value="item"
            />
          </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="queryTableInfo">
          <el-icon>
            <Search/>
          </el-icon>
          <span>搜索</span>
        </el-button>
        <el-button type="success" @click="handleAdd">
          <el-icon>
            <CirclePlusFilled/>
          </el-icon>
          <span>添加标签</span>
        </el-button>
        <el-button type="primary"
                   :disabled="selectedIds === null || selectedIds.length === 0"
                   @click="handleEditTagGroupName">
          <el-icon>
            <Check/>
          </el-icon>
          <span>批量修改组名</span>
        </el-button>
      </el-form-item>
    </el-form>
    <!--表格数据-->
    <el-table :data="tableInfoList" v-loading="loading" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"/>
      <el-table-column prop="name" label="标签名"></el-table-column>
      <el-table-column prop="code" label="标签码"/>
       <el-table-column prop="material_num" label="标签类型" width="150">
        <template #default="scope">
          <el-tag>{{ tagTypeName(scope.row.type) }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="group_name" label="标签组名"/>
      <el-table-column prop="material_count" label="关联素材数" width="100">
        <template #default="scope">
          <el-button :disabled="scope.row.material_count <= 0" type="primary" link
                     @click="handleClickProductVideo(scope.row)">{{ scope.row.material_count }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间"/>
      <el-table-column prop="update_time" label="更新时间"/>
      <el-table-column prop="status" label="状态">
        <template #default="scope">
          <el-tag
              :type="scope.row.status === 1 ? 'success' : 'warning'"
              disable-transitions
          >{{ scope.row.status === 1 ? '有效' : '无效' }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注" width="160"></el-table-column>
      <el-table-column fixed="right" label="操作" width="160">
        <template #default="scope">
          <div style="display: flex; flex-direction: column; align-items: flex-start;">
            <el-button link disabled></el-button>
            <el-button style="margin-bottom: 5px;" link type="primary" @click.prevent="handleEdit(scope.row)">编辑
            </el-button>
            <el-button style="margin-bottom: 5px;" link type="danger" @click.prevent="handleDelete(scope.row)">
              删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--分页数据-->
    <pagination v-show="total > 0" :total="total" v-model:page="queryParams.pageNum"
                v-model:limit="queryParams.pageSize" @pagination="queryTableInfo"/>
    <!--添加标签-->
    <el-dialog title="添加标签"
               v-model="showAddDialog"
               width="40%"
               :close-on-click-modal="false">
      <el-form
          :model="addForm"
          ref="addForm"
          label-position="left"
          label-width="auto"
          style="max-width: 600px">
        <el-form-item label="标签名：" prop="name" required>
          <el-input v-model="addForm.name"
                    placeholder="请输入标签名">
          </el-input>
        </el-form-item>
        <el-form-item label="标签类型：" prop="type" required>
          <el-select v-model="addForm.type">
            <el-option label="系统标签" value="system">系统标签</el-option>
            <el-option label="自定义标签" value="custom">自定义标签</el-option>
            <el-option label="微信标签" value="wechat">微信标签</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标签组名：" prop="groupName">
          <el-select
              v-model="addForm.groupName"
              filterable
              allow-create
              default-first-option
              :reserve-keyword="false"
              placeholder="选择已有分组名或则新增">
            <el-option
                v-for="item in tagGroup"
                :key="item"
                :label="item"
                :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input
              placeholder="可选，请输入备注"
              v-model="addForm.remark"/>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="showAddDialog = false">取消</el-button>
          <el-button type="primary" @click="submitForm('addForm')" :loading="submitLoading">提交</el-button>
        </div>
      </template>
    </el-dialog>

    <!--编辑标签-->
    <el-dialog title="编辑标签"
               v-model="editAddDialog"
               width="40%"
               :close-on-click-modal="false">
      <el-form
          :model="selectedTagInfo"
          label-position="left"
          label-width="auto"
          style="max-width: 600px"
      >
        <el-form-item label="标签名：" prop="name" required>
          <el-input v-model="selectedTagInfo.name"
                    placeholder="请输入标签名">
          </el-input>
        </el-form-item>
        <el-form-item label="标签类型：" prop="type" required>
          <el-select v-model="selectedTagInfo.type">
            <el-option label="系统标签" value="system">系统标签</el-option>
            <el-option label="自定义标签" value="custom">自定义标签</el-option>
            <el-option label="微信标签" value="wechat">微信标签</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标签组名：" prop="groupName">
          <el-select
              v-model="selectedTagInfo.group_name"
              filterable
              allow-create
              default-first-option
              :reserve-keyword="false"
              placeholder="选择已有分组名或则新增">
            <el-option
                v-for="item in tagGroup"
                :key="item"
                :label="item"
                :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input
              placeholder="可选，请输入备注"
              v-model="selectedTagInfo.remark"/>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="editAddDialog = false">取消</el-button>
          <el-button type="primary" @click="saveEditInfo" :loading="submitLoading">保存</el-button>
        </div>
      </template>
    </el-dialog>

    <!--批量修改分组名-->
    <el-dialog title="批量修改标签分组"
               v-model="batchModifyTagGroupDialog"
               width="40%"
               :close-on-click-modal="false">
      <el-form
          label-position="left"
          label-width="auto"
          style="max-width: 600px">
        <el-form-item label="标签组名：" prop="groupName">
          <el-select
              v-model="newTagGroupName"
              filterable
              allow-create
              default-first-option
              :reserve-keyword="false"
              placeholder="选择已有分组名或则新增">
            <el-option
                v-for="item in tagGroup"
                :key="item"
                :label="item"
                :value="item"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="batchModifyTagGroupDialog = false">取消</el-button>
          <el-button type="primary" @click="saveModifyTagName" :loading="submitLoading">提交</el-button>
        </div>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import axiosInstance from '@/api/axiosInstance';
import {CirclePlusFilled, Search, Download, Check, Position} from '@element-plus/icons-vue';
import Pagination from "@/components/pagination/index.vue";

export default {
  name: "luma-account",
  components: {
    Position,
    Pagination,
    CirclePlusFilled, Search, Download, Check
  },
  computed: {
    isLogin() {
      return this.$store.state.isLogin;
    },
    isSuperAdmin() {
      return this.$store.state.isSuperAdmin;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  data() {
    const validateCookie = (rule, value, callback) => {
      if (!value) {
        callback(new Error('Cookie不能为空'));
      }
      callback();
    };
    return {
      selectedIds: [],
      queryParams: {
        tenantId: '',
        pageNum: 1,
        pageSize: 20,
        name: "",
        groupName: "",
        type: "", // 标签类型 system 系统内置； custom 用户自定义, wechat 微信小程序
      },
      tagGroup: [],
      tenantInfoList: [],
      reportTableData: [],
      loading: false,
      total: 0,

      loginRecord: {},
      tableInfoList: [],
      showAddDialog: false,
      submitLoading: false,
      addForm: {
        name: '',
        type: 'custom',
        remark: '',
        groupName: null, // 标签分组名
      },
      editAddDialog: false,
      selectedTagInfo: {},
      batchModifyTagGroupDialog: false,
      newTagGroupName: null,
    };
  },
  created() {
    this.queryTableInfo();
  },
  mounted() {
    this.queryAllTenantId();
    this.queryTagGroup();
  },
  methods: {
    handleSelectionChange(rows) {
      console.log("handleSelectionChange", rows)
      // rows 对象数组 取id字段 转对应数组
      this.selectedIds = rows.map(item => item.id) || [];
    },
    handleEditTagGroupName() {
      this.batchModifyTagGroupDialog = true;
    },
    handleClickProductVideo(row) {
      let path = `/content/material?tag=${row.name}`;
      this.$router.push(path);
    },
    queryTagGroup() {
      axiosInstance.get('/api/tag/query_tag_group').then(response => {
        console.log("queryTagGroup, response:", response)
        if (response.data && response.data.code === 0) {
          this.tagGroup = response.data.data || [];
        } else {
          this.tagGroup = []
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        this.tagGroup = []
        console.error(error);
      });
    },
    queryAllTenantId() {
      if (this.isSuperAdmin) {
        axiosInstance.get('/api/tenant')
            .then(response => {
              if (response.data && response.data.code === 0) {
                this.tenantInfoList = response.data.data;
              } else {
                this.$message.error(response.data.msg);
              }
            }).catch(error => {
          console.error(error);
        });
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveAddInfo();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    saveAddInfo() {
      this.submitLoading = true;
      axiosInstance.post('/api/tag/create', this.addForm).then(response => {
        if (response.data && response.data.code === 0) {
          this.$message.success("新增成功");
        } else {
          this.$message.error(response.data.msg);
        }
        this.submitLoading = false;
        this.showAddDialog = false;
        this.queryTableInfo();
      }).catch(error => {
        console.error(error)
        this.submitLoading = false;
        this.showAddDialog = false;
      });
    },
    saveEditInfo() {
      if (!this.selectedTagInfo.name) {
        this.$message.error("标签名称不能为空");
        return;
      }
      this.submitLoading = true;
      axiosInstance.post('/api/tag/update', {
        id: this.selectedTagInfo.id,
        name: this.selectedTagInfo.name,
        type: this.selectedTagInfo.type,
        remark: this.selectedTagInfo.remark,
        groupName: this.selectedTagInfo.group_name,
      }).then(response => {
        if (response.data && response.data.code === 0) {
          this.$message.success("新增成功");
        } else {
          this.$message.error(response.data.msg);
        }
        this.submitLoading = false;
        this.editAddDialog = false;
        this.queryTableInfo();
        this.queryTagGroup();
      }).catch(error => {
        console.error(error)
        this.submitLoading = false;
        this.editAddDialog = false;
        this.$message.error("保存失败，请重试");
      });
    },
    saveModifyTagName() {
      if (!this.newTagGroupName || this.newTagGroupName.trim() === '') {
        this.$message.error("请选择或则新增标签分组")
        return
      }
      this.submitLoading = true;
      axiosInstance.post('/api/tag/update_tag_group', {
        ids: this.selectedIds,
        groupName: this.newTagGroupName,
      }).then(response => {
        if (response.data && response.data.code === 0) {
          this.$message.success("更新成功");
        } else {
          this.$message.error(response.data.msg);
        }
        this.submitLoading = false;
        this.batchModifyTagGroupDialog = false;
        this.queryTableInfo();
        this.queryTagGroup();
      }).catch(error => {
        console.error(error)
        this.submitLoading = false;
        this.batchModifyTagGroupDialog = false;
        this.$message.error("更新失败，请重试");
      });
    },
    queryTableInfo() {
      this.loading = true
      axiosInstance.post('/api/tag/list', this.queryParams).then(response => {
        if (response.data && response.data.code === 0) {
          this.tableInfoList = response.data.data.results;
          this.total = response.data.data.total;
        } else {
          this.$message.error(response.data.msg);
        }
        this.loading = false
      }).catch(error => {
        this.loading = false
        console.error(error)
      });
    },
    handleAdd() {
      this.showAddDialog = true;
      this.queryTagGroup();
    },
    handleEdit(row) {
      this.editAddDialog = true;
      this.selectedTagInfo = row;
    },
    handleDelete(row) {
      let that = this;
      this.$confirm('此操作将彻底删除标签，标签绑定的素材仍会保留, 是否继续?', '确认删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 用户点击了确定按钮，发送删除请求
        axiosInstance.post('/api/tag/delete', {
          "id": row.id,
          "force": true,
        }).then(response => {
          that.$message({
            type: 'success',
            message: '删除成功!'
          });
          // 删除成功后，刷新用户信息列表
          that.queryTableInfo();
        }).catch(error => {
          that.$message({
            type: 'error',
            message: '删除失败: ' + error.message
          });
        });
      }).catch(() => {
        // 用户点击了取消按钮
        that.$message({
          type: 'info',
          message: '已取消删除操作'
        });
      });
    },
    tagTypeName(type) {
      type = type.toLowerCase();
      if (type === 'system') {
        return '系统标签';
      } else if (type === 'custom') {
        return '自定义标签';
      } else if (type === 'wechat') {
        return '微信小程序标签';
      } else {
        return '未知标签';
      }
    },
  },
};
</script>

<style scoped>
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 20px;
}

.main-header-h1 {
  text-align: center;
  color: #333;
}

#button-container {
  text-align: center;
  margin-bottom: 20px;
}

.add-user-button {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-user-button:hover {
  background-color: #0056b3;
}

#cookie-info-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.cookie-info {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.cookie-info h2 {
  margin: 0 0 10px;
  font-size: 1.5em;
  font-weight: 600;
  color: #333;
}

.cookie-info span {
  margin-bottom: 5px;
  font-size: 1em;
  color: #555;
}

.cookie-status-valid {
  color: green;
}

.cookie-status-invalid {
  color: red;
}

.step-content {
  text-align: center;
  margin-top: 20px;
}

.highlight {
  color: red;
  font-weight: bold;
}
</style>