<template>
  <svg t="1727400766661" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
       p-id="14665" :width="width" :height="height">
    <path
        d="M647.3 179.5h0.2-0.2m-366 0.5h0.1-0.1m463.4 2l155.5 155.5-78.1 93.7c-25.9 31.1-40.2 70.5-40.2 110.9V812c0 16.5-13.5 30-30 30H272c-16.5 0-30-13.5-30-30V542.1c0-40.4-14.3-79.8-40.2-110.9l-78.1-93.7L279.3 182h99.5c33 39.8 81.4 62.5 133.2 62.5s100.2-22.7 133.2-62.5h99.5m2.8-90H642c-25.3 0-49.2 11.5-65 31.2-16.7 20.8-40.8 31.2-65 31.2s-48.3-10.4-65-31.2A83.33 83.33 0 0 0 382 92H276.5c-22.1 0-43.2 8.8-58.9 24.4L55.8 278.2c-30.4 30.4-32.7 79.1-5.1 112.2l82 98.4c12.5 15 19.3 33.8 19.3 53.3V812c0 66.3 53.7 120 120 120h480c66.3 0 120-53.7 120-120V542.1c0-19.5 6.8-38.3 19.3-53.3l82-98.4c27.6-33.1 25.4-81.7-5.1-112.2L806.4 116.4A83.338 83.338 0 0 0 747.5 92z"
        fill="#1296db" p-id="14666"></path>
    <path
        d="M512 526.9c-26.9 0-53.8-10.2-74.2-30.7l-106.1-106c-17.6-17.6-17.6-46.1 0-63.6s46.1-17.6 63.6 0l106.1 106.1c5.9 5.9 15.4 5.8 21.2 0l106.1-106.1c17.6-17.5 46.1-17.6 63.6 0s17.6 46.1 0 63.6L586.2 496.3c-20.4 20.4-47.3 30.6-74.2 30.6z"
        fill="#4C4F54" p-id="14667"></path>
  </svg>
</template>

<script>
export default {
  name: "TopGarmentIcon",
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    },
  }
};
</script>

<style scoped>
</style>
