<template>
  <svg width="25" height="29" viewBox="0 0 25 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1784_519)">
      <path d="M12.3891 28.61L0 21.4568L12.3891 14.3037L24.7781 21.4568L12.3891 28.61Z" fill="white"
            fill-opacity="0.66"></path>
      <path d="M0 7.15157L12.3891 0V28.611L0 21.4578V7.15157Z" fill="white" fill-opacity="0.65"></path>
    </g>
    <defs>
      <clipPath id="clip0_1784_519">
        <rect width="25" height="29" fill="white"></rect>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'LumaIcon',
};
</script>

<style scoped>
</style>