<template>
  <svg t="1727415639551" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
       p-id="15381" :width="width" :height="height">
    <path
        d="M881.777778 910.222222h-739.555556c-45.511111 0-85.333333-39.822222-85.333333-85.333333v-625.777778C56.888889 153.6 96.711111 113.777778 142.222222 113.777778h739.555556c45.511111 0 85.333333 39.822222 85.333333 85.333333v625.777778c0 45.511111-39.822222 85.333333-85.333333 85.333333zM142.222222 170.666667c-17.066667 0-28.444444 11.377778-28.444444 28.444444v625.777778c0 17.066667 11.377778 28.444444 28.444444 28.444444h739.555556c17.066667 0 28.444444-11.377778 28.444444-28.444444v-625.777778c0-17.066667-11.377778-28.444444-28.444444-28.444444h-739.555556z"
        fill="#1296db" p-id="15382"></path>
    <path
        d="M512 711.111111c-17.066667 0-28.444444-11.377778-28.444444-28.444444V341.333333c0-17.066667 11.377778-28.444444 28.444444-28.444444s28.444444 11.377778 28.444444 28.444444v341.333334c0 17.066667-11.377778 28.444444-28.444444 28.444444zM341.333333 654.222222c-17.066667 0-28.444444-11.377778-28.444444-28.444444V398.222222c0-17.066667 11.377778-28.444444 28.444444-28.444444s28.444444 11.377778 28.444445 28.444444v227.555556c0 17.066667-11.377778 28.444444-28.444445 28.444444zM682.666667 654.222222c-17.066667 0-28.444444-11.377778-28.444445-28.444444V398.222222c0-17.066667 11.377778-28.444444 28.444445-28.444444s28.444444 11.377778 28.444444 28.444444v227.555556c0 17.066667-11.377778 28.444444-28.444444 28.444444z"
        fill="#1296db" p-id="15383"></path>
  </svg>
</template>

<script>
export default {
  name: "TryOnResultIcon",
  props: {
    width: {
      type: Number,
      default: 24
    },
    height: {
      type: Number,
      default: 24
    },
  }
};
</script>

<style scoped>
</style>
