<template>
  <svg t="1727341946264" class="icon" viewBox="0 0 1102 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
       p-id="4434" width="200" height="200">
    <path
        d="M969.412923 853.858462l-167.187692-283.136v-3.229539H304.679385l-169.03877 286.404923 244.499693 166.242462 172.386461-157.814154 172.386462 157.814154 244.499692-166.242462z m98.776615-553.826462L802.264615 34.067692V32.019692L691.593846 4.292923c-13.115077 52.854154-71.640615 92.672-141.784615 92.672-69.710769 0-127.842462-39.227077-141.587693-91.608615L301.922462 31.901538v0.905847L34.697846 300.032l152.064 152.064 115.160616-115.121231v167.975385h500.302769V338.116923l114.018461 113.979077 151.945846-152.024615z"
        fill="#FFFFFF" p-id="4435"></path>
  </svg>
</template>

<script>
export default {
  name: 'ClothIcon',
};
</script>

<style scoped>
</style>