<template>
  <div class="login-card">
    <h1>IAimarket</h1>
    <el-tabs v-model="loginForm.loginType" class="login-tabs" tab-position="top" style="justify-content: center;">
      <el-tab-pane label="短信登录" name="sms" class="tab-pane-centered">
        <el-form :model="loginForm" ref="loginForm" label-width="0px">
          <el-form-item>
            <el-input v-model="loginForm.phoneNumber" placeholder="请输入手机号">
              <template #prepend>+86</template>
            </el-input>
          </el-form-item>
          <el-form-item class="verification-code-item">
            <el-input style="flex: 2;" v-model="loginForm.verificationCode" placeholder="请输入验证码"></el-input>
            <el-button style="flex: 1;" @click="sendCode" :disabled="isSendingCode" :loading="sendCodeLoading"
                       class="send-code-button">
              获取验证码 {{ isSendingCode ? `(${countDown})` : '' }}
            </el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="账号登录" name="password" class="tab-pane-centered">
        <el-form :model="loginForm" label-width="0px">
          <el-form-item>
            <el-input v-model="loginForm.username" placeholder="请输入用户名" @keyup.enter="login"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input type="password" v-model="loginForm.password" placeholder="请输入密码"
                      @keyup.enter="login"></el-input>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <el-button type="primary" @click="login">登录</el-button>
    <div class="agreement-container">
      <span>登录/注册即表示您同意我们的</span>
      <a href="/user-agreement" target="_blank">用户协议</a>
      <span>和</span>
      <a href="/privacy-policy" target="_blank">隐私协议</a>
    </div>
  </div>
</template>

<script>
import axios from '@/api/axiosInstance';
import sha256 from 'crypto-js/sha256';

export default {
  data() {
    return {
      loginForm: {
        loginType: 'sms', // sms or password
        phoneNumber: '',
        verificationCode: '',
        username: '',
        password: '',
      },
      sendCodeLoading: false,
      isSendingCode: false,
      countDown: 60, // 倒计时秒数
      timer: null,
    };
  },
  mounted() {
    this.updateLoginStatus();
  },
  methods: {
    updateLoginStatus() {
      if (localStorage.getItem('loginData')) {
        this.$store.dispatch('login', JSON.parse(localStorage.getItem('loginData')));
      } else {
        this.$store.dispatch('logout');
      }
    },
    login() {
      // 复制loginForm对象
      let loginData = {...this.loginForm};
      // 检查当前是短信登录还是账号密码登录
      if (this.loginForm.loginType === 'sms') {
        if (!this.loginForm.phoneNumber || !this.loginForm.verificationCode) {
          this.$message.error('请输入手机号和验证码');
          return;
        }
      } else {
        if (!this.loginForm.username || !this.loginForm.password) {
          this.$message.error('请输入用户名和密码');
          return;
        }
        // 账号密码登录时，将密码进行SHA256加密
        loginData.password = sha256(this.loginForm.password).toString();
      }

      axios.post('/api/login', loginData).then(response => {
        console.log("login response::::", response)
        if (response.data.code === 0) {
          localStorage.setItem('loginData', JSON.stringify(response.data.data));
          this.$store.dispatch('login', response.data.data);
          this.$message.success('登录成功');
          // 发出登录成功的事件
          this.$emit('login-success');
        } else {
          localStorage.clear();
          this.$store.dispatch('logout', response.data.data);
          this.$message.error(response.data.msg);
        }
      }).catch(error => {
        console.error(error);
        this.$message.error('登录失败，请检查账号或密码');
      });
    },
    sendCode() {
      if (!this.loginForm.phoneNumber) {
        this.$message.error('请输入手机号');
        return;
      }
      // 校验手机号格式
      if (!/^1[3456789]\d{9}$/.test(this.loginForm.phoneNumber)) {
        this.$message.error('请输入正确的手机号');
        return;
      }
      this.sendCodeLoading = true;
      axios.post('/api/login/send_sms_code', {phoneNumber: this.loginForm.phoneNumber})
          .then(response => {
            console.log(response)
            this.sendCodeLoading = false;
            if (response.data.code === 0) {
              this.$message.success('验证码已发送');
              this.startCountDown();
            } else {
              this.$message.error(response.data.msg);
            }
          })
          .catch(error => {
            this.sendCodeLoading = false;
            console.error(error);
            this.$message.error('验证码发送失败，请重试！');
            this.clearCountDown();
          });
    },
    startCountDown() {
      this.isSendingCode = true;
      this.countDown = 60;
      this.timer = setInterval(() => {
        if (this.countDown > 0) {
          this.countDown--;
        } else {
          this.isSendingCode = false;
          clearInterval(this.timer);
        }
      }, 1000);
    },
    clearCountDown() {
      // 确保组件销毁时清除倒计时
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.isSendingCode = false;
      this.countDown = 0;
    },
  },

  beforeUnmount() {
    this.clearCountDown();
  }
};
</script>

<style scoped>

.login-card {
  background: linear-gradient(to bottom, #ff9a9e 0%, #fad0c4 20%, #c6e2ff 60%, #ffffff 100%);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  width: 400px;
  text-align: center;
  z-index: 10;
}

h1 {
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 24px;
}

.login-tabs {
  margin-bottom: 20px;
  justify-content: center;
}

.el-tabs__active-bar {
  display: none; /* Remove the underline */
}

.el-tabs__nav {
  justify-content: center; /* Center the tab items */
}

.verification-code-item {
  display: flex;
}

.send-code-button {
  margin-left: 10px;
}

.login-button {
  width: 100%;
  height: 40px;
  background-color: #ff4081;
  border-radius: 20px;
  margin-top: 20px;
}

.agreement-container {
  margin-top: 20px;
  text-align: center;
  color: #999;
  font-size: 14px;
}

.agreement-container a {
  color: #24a5ff;
  font-size: 14px;
}

</style>